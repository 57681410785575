<template>
  
  <el-container>
    <!-- 头部 -->
    <el-header style="height: 75px; background-color: #052531;" v-if="$store.state.someData?.someData ? false: true">
      <el-row>
        <!-- <div class="logo"> -->
          <!-- <img src="../assets/blank.png" alt/> -->
<!--          <span>GPS Management System</span>-->
        <!-- </div> -->
      <!-- 1{{$store.state.someData?.someData ? false: true}}1 -->
        <!-- <div class="line" v-if="eventName">{{eventName}}, 开始时间{{convertDateToString(eventStartTime)}},结束时间:{{ convertDateToString(eventEndTime) }}</div> -->
        <div style="display: flex; flex-direction: column; margin-left: 300px;" v-if="eventName">
          <div class="tech-text">{{eventName}}</div>
          <!-- TODO 比赛时间-->
          <div style="font-size: 18px;  color:#CCCCCC; ">开始时间:<span style="font-size: 18px; color:#3498DB;"  class="tech-text" >{{convertDateToString(eventStartTime)}}</span></div>
          <div style="font-size: 18px; color:#CCCCCC; ">结束时间:<span  style="font-size: 18px; color:#F56C6C;;"  class="tech-text-end" >{{ convertDateToString(eventEndTime) }}</span></div>
        </div>
        <!-- 导航隐藏了-->
        <el-menu
        v-if="false"
          default-active="/competition/competitionList"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          active-text-color="#6dad99">
<!--          <el-submenu index="gpsUser" class="gpsUser">
            <template slot="title">Gps User</template>
            <el-menu-item index="/gpsUser/userList">User List</el-menu-item>
            <el-menu-item index="/gpsUser/userBind">User Device Bind</el-menu-item>
          </el-submenu>-->
          <!-- <el-submenu index="device" class="device">
            <template slot="title">{{$t('home.page.head.menu.deviceTitle')}}</template> -->
           
            <el-menu-item index="/home">{{$t('home.page.head.menu.mapCenter')}}</el-menu-item>
            <el-menu-item index="/device/deviceList">{{$t('home.page.head.menu.deviceList')}}</el-menu-item>
            <el-menu-item index="/competition/competitionList">{{$t('home.page.head.menu.competitionManage')}}</el-menu-item>
            <el-menu-item index="/pigeon/pigeonGuild">{{$t('home.page.head.menu.PigeonGuild')}}</el-menu-item>
<!--            <el-menu-item index="/gpsUser/userBind">{{$t('home.page.head.menu.userBind')}}</el-menu-item>-->
<!--            <el-menu-item index="/device/deviceFence">Device Fence</el-menu-item>-->
<!--            <el-menu-item index="/device/deviceStatistic/reportMain">Statistic Report</el-menu-item>-->
<!--            <el-submenu index="">-->
<!--            <template slot="title">Statistic Report</template>-->
<!--            <el-menu-item index="/device/deviceStatistic/alterReport">Alert Report</el-menu-item>-->
<!--            <el-menu-item index="/device/deviceStatistic/speedReport">Over Speed Report</el-menu-item>-->
<!--            <el-menu-item index="/device/deviceStatistic/reportMain">选项3</el-menu-item>-->
<!--            </el-submenu>-->
          <!-- </el-submenu> -->
          <!-- <el-submenu index="reportMain" class="reportMain">
            <template slot="title">{{$t('home.page.head.menu.reportTitle')}}</template> -->
            <el-menu-item index="/device/deviceStatistic/reportMain">{{$t('home.page.head.menu.reportMain')}}</el-menu-item>
            <!-- <el-menu-item index="/configMapGmap/configMapGmap">{{$t('home.page.head.menu.mapCenter')}}</el-menu-item> -->

            <!-- <el-menu-item index="/configMapGmap/configMapGmap">{{$t('home.page.head.menu.mapCenter')}}</el-menu-item> -->
            <!-- </el-submenu> -->
          <!-- <el-submenu index="systemMain" class="systemMain" v-if="globalParameters.verType === 'standalone'">
            <template slot="title" >{{$t('home.page.head.menu.systemMain')}}</template> -->
            <!-- <el-menu-item index="/system/systemMain">{{$t('home.page.head.menu.systemMain')}}</el-menu-item> -->
          <!-- </el-submenu> -->
          <el-submenu index="systemManager" class="systemManager" v-if="globalParameters.verType === 'platform'">
<!--          <el-submenu index="systemManager" class="systemManager" v-if="isAdmin">-->
            <template slot="title" v-if="globalParameters.verType === 'platform'">{{$t('home.page.head.menu.systemTitle')}}</template>
            <el-menu-item v-if="globalParameters.verType === 'platform'" index="/system/users">{{$t('home.page.head.menu.users')}}</el-menu-item>
            <el-menu-item v-if="globalParameters.verType === 'platform'" index="/system/dept">{{$t('home.page.head.menu.dept')}}</el-menu-item>
            <el-menu-item v-if="globalParameters.verType === 'platform' && isAdmin" index="/system/code" >{{$t('home.page.head.menu.code')}}</el-menu-item>
            <el-menu-item v-if="globalParameters.verType === 'platform'" index="/system/customer">{{$t('home.page.head.menu.customer')}}</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-row>
      <div class="feature_box">
<!--        <el-dropdown @command="handleCommand">-->
<!--          <span class="el-dropdown-link">-->
<!--            下拉菜单<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--          </span>-->
<!--          <el-dropdown-menu slot="dropdown">-->
<!--            <el-dropdown-item>English</el-dropdown-item>-->
<!--            <el-dropdown-item>中文</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
        <label class="label-welcome">{{$t('home.page.head.welcome')}}<span>{{userName}}</span></label>
        <el-button v-if="userName !== 'experience'" type="info" @click="openChangePassword" class="button-changePass">{{$t('home.page.head.changePasswd')}}</el-button>
        <el-button type="info" @click="logout" class="button-logout">{{$t('home.page.head.logout')}}</el-button>
      </div>
    </el-header>
    <!-- 主体 -->
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    
    <el-dialog :title="$t('home.page.diagTitle')"
               :visible.sync="changePasswordDialogVisible"
               width="20%"
               @close="changePasswordDialogClosed">
      <el-form
        :model="changePasswordForm"
        ref="changePasswordFormRef"
        :rules="changePasswordFormRules"
        label-width="130px"
      >
        <el-form-item :label="$t('home.page.changePasswordForm.oldPassword')" prop="oldPassword" >
          <el-input type="password" v-model="changePasswordForm.oldPassword" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.page.changePasswordForm.newPassword1')" prop="newPassword1">
          <el-input type="password" v-model="changePasswordForm.newPassword1" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.page.changePasswordForm.newPassword2')" prop="newPassword2">
          <el-input type="password" v-model="changePasswordForm.newPassword2" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordDialogVisible = false">{{$t('home.page.pwdBtnCancel')}}</el-button>
        <el-button type="primary" @click="changePasswordHandle">{{$t('home.page.pwdBtnConfirm')}}</el-button>
      </span>
    </el-dialog>
<!--    <el-footer>-->
<!--      <el-row>-->
<!--        <span>GPS Management System</span>-->
<!--      </el-row>-->
<!--    </el-footer>-->
  </el-container>
</template>

<script>
import router from '@/router'
import { changePassword } from '@/api/sysUser'
import { checkLogin } from '@/api/globalProcessErr'
import { globalParameters } from '@/common/globalVal'
import moment from 'moment'

export default {
  computed: {
    someData() {
      return this.$store.state.someData;
    },
    someDataTruthy() {
    const value = this.$store.state.someData.someData;
    return value != null && value !== false && value !== '';
  }
  },

  data () {
    return {
      showHeader: true, // 控制隐藏显示头部
      eventStartTime: 0, //   开始时间
      eventEndTime: 0,  // 结束时间
      eventName: '',  // 赛事名称

      menuList: [ ],
      // 默认不折叠
      isCollapse: false,
      userId: undefined,
      isAdmin: false,
      userName: '',
      changePasswordDialogVisible: false,
      changePasswordForm: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: ''
      },
      globalParameters: globalParameters,
      pwdType: '',
      showSystemTool: true,

    }
  },
  computed: {
    changePasswordFormRules () {
      return {
        oldPassword: [
          { required: true, message: this.$t('home.rules.oldPassword.required'), trigger: 'blur' }
        ],
        newPassword1: [
          { required: true, message: this.$t('home.rules.newPassword1.required'), trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: this.$t('home.rules.newPassword2.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.userId = window.sessionStorage.getItem('userId')
    this.userName = window.sessionStorage.getItem('userName')
    this.pwdType = window.sessionStorage.getItem('pwdType')

    // TODO 从缓存获取赛事名称 开始 和结束
    this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || ''
    this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
    this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || ''

    // console.log(' 从缓存获取赛事名称 开始 和结束:', this.eventName,this.eventStartTime,this.eventEndTime)
    // console.log(' 从缓存获取赛事名称 开始 和结束:', this.convertDateToString(this.eventStartTime),this.convertDateToString(this.eventEndTime))
  },
  mounted () {
    let pwdType = sessionStorage.getItem("pwdType")
    if (pwdType == '2') {
      this.showSystemTool = true;
    }
    if (this.userId === '1') {
      this.isAdmin = true
    }
  },
  methods: {
    hideHeader(data){
      console.log('data:', data)
    },
    convertDateToString (date) {
      if (date) {
        
        return moment(date ).format("yyyy-MM-DD HH:mm:ss")
      } else {
        return ''
      }
    },
    logout () {
      
      // 清空token
      window.sessionStorage.clear()

         // 清空url传递的参数
         window.location.href = "/";
      // this.$router.push('/login')

    },
    handleSelect (key, keyPath) {
      router.push({ path: key.toString() })
    },
    changePasswordHandle () {
      const self = this
      if (this.changePasswordForm.newPassword1 !== this.changePasswordForm.newPassword2) {
        return self.$message.error(self.$t('home.msg.pwdDiff'))
      }
      const _this = this
      const req = {
        userId: this.userId,
        oldPassword: this.changePasswordForm.oldPassword,
        newPassword: this.changePasswordForm.newPassword1
      }
      changePassword(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.$message.info(_this.$t('home.msg.changedSuccess'))
        _this.changePasswordDialogVisible = false
      }).catch(err => {
        return _this.$message.error(err)
      })
    },
    openChangePassword () {
      this.changePasswordDialogVisible = true
    },
    changePasswordDialogClosed () {
      this.$refs.changePasswordFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>



.el-container {
  height: calc(100% - 30px);
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.logo {
  width: 280px;
  margin-left: 0;
  padding-left: 0;
  left: -7%!important;
  position: relative;
}

.el-header {
  //background-color: #373f41;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 0;
  align-items: center;
  //color: #fff;
  font-size: 20px;
  margin: 0 0 0 0;

  > div {
    display: flex;
    align-items: center;

    img {
      height: 50px;
      margin-left: 30%;
    }

    span {
      margin-left: 15px;
    }
  }

  .el-menu{
    //background-color: #2b464e;
    font-size: 25px;
  }
  .feature_box{
    padding-left: 0;
  }

  .label-welcome {
    display: inline-block;
    text-align:center;
    font-family: "Times New Roman", Times, serif;
    // background-color: #FFFFFF;
    font-size: 14px;
    color: #CCCCCC;
    border: none;
  }
  .label-welcome span{
    color: #3498DB;
    margin: 3px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }

  .button-changePass{
    display: inline-block;
    text-align:center;
    font-family: "Times New Roman", Times, serif;
    background-color: #3498DB;
    font-size: 14px;
    color: #ffffff;
    border: none;
    padding: 12px 0 12px 12px;
    margin: 0;
  }
  .button-logout{
    display: inline-block;
    text-align:center;
    font-family: "Times New Roman", Times, serif;
    background-color: #F56C6C;
    font-size: 14px;
    color: #ffffff;
    border: none;
    padding: 12px;
    margin-right: 10px;
  }
}

.gpsUser {
}

.device {

}

.systemManager {

}
/** TODO 背景颜色 */

.el-main {
  background-color: #052531;
}

.iconfont {
  margin-right: 10px;
}

.el-footer {
  height: 100px;
  padding-top: 15px;
  text-align: center;
  background-color: #eaedf1;
}

.toggle-button {
  background-color: #6dad99;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  // 鼠标放上去变成小手
  cursor: pointer;
}

.test-text {
  font-family: 'Roboto Mono', monospace; /* 使用现代等宽字体 */
  
  color: white; /* 设置文字颜色为白色 */
  text-shadow: 0 0 10px blue; /* 添加蓝色文字阴影效果 */
  background: linear-gradient(90deg, blue, cyan); /* 背景渐变效果从蓝色到青色 */
  -webkit-background-clip: text; /* 背景裁剪到文字形状 */
  -webkit-text-fill-color: transparent; /* 文字填充颜色设置为透明，以显示背景渐变 */

}

.tech-text {
  font-size: 24px; /* 增大文字大小 */
  font-family: 'Roboto Mono', monospace; /* 使用现代等宽字体 */
  color: blue; /* 使用科技感的颜色 */
  text-shadow: 0 0 10px #00cc77, 0 0 20px #00cc77, 0 0 30px #00cc77, 0 0 40px #009955; /* 添加文字阴影效果 */
  animation: techAnimation 2s infinite; /* 应用动画效果 */
}

@keyframes techAnimation {
  0% {
    text-shadow: 0 0 10px #00cc77, 0 0 20px #00cc77, 0 0 30px #00cc77, 0 0 40px #009955;
  }
  50% {
    text-shadow: 0 0 15px #00ff99, 0 0 30px #00ff99, 0 0 45px #00ff99, 0 0 60px #00cc77;
  }
  100% {
    text-shadow: 0 0 10px #00cc77, 0 0 20px #00cc77, 0 0 30px #00cc77, 0 0 40px #009955;
  }
}

.tech-text-end {
  font-size: 24px; /* 增大文字大小 */
  font-family: 'Roboto Mono', monospace; /* 使用现代等宽字体 */
  color: rgb(255, 0, 0); /* 使用科技感的颜色 */
  text-shadow: 0 0 10px #cc0000, 0 0 20px #cc0000, 0 0 30px #cc0000, 0 0 40px #990000; /* 添加文字阴影效果 */
  animation: techAnimationEnd 2s infinite; /* 应用动画效果 */
}
@keyframes techAnimationEnd {
  0% {
    text-shadow: 0 0 10px #b70b0bec, 0 0 20px #cc0000, 0 0 30px #cc0000, 0 0 40px #990000;
  }
  50% {
    text-shadow: 0 0 15px #ff0000, 0 0 30px #ff0000, 0 0 45px #ff0000, 0 0 60px #cc0000;
  }
  100% {
    text-shadow: 0 0 10px #cc0000, 0 0 20px #cc0000, 0 0 30px #cc0000, 0 0 40px #990000;
  }
}

</style>
