import request from '@/api/request'

export function getDeviceTrackLog (data) {
  const req = {
    deviceImei: data.deviceImei,
    startTime: data.startTime,
    endTime: data.endTime,
    nextId: data.nextId,
    requestRows: data.requestRows,
    accOn: data.accOn
  }
  return request({
    url: 'app/gps/location/deviceTrackLog/',
    method: 'post',
    data: req
  })
}

export function getDeviceHistoryTrajectoryLog (data) {
  const req = {
    deviceImei: data.deviceImei,
    startTime: data.startTime,
    endTime: data.endTime,
    nextId: data.nextId,
    requestRows: data.requestRows
  }
  return request({
    url: 'app/gps/location/getLastConfigDeviceTraceLog',
    method: 'post',
    data: req
  })
}
