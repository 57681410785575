<template>
    <div style="height: 100%;">

        <!-- 轨迹回放页面 -->
        <!--  style="height: 90%;" 动画节点  v-if="colseload == 0" -->
        <div id="loader-wrapper" v-if="colseload == 0">
            <div id="loader"></div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
            <div class="load_title">正在加载,请耐心等待
                <br>
                <!-- <span>V0.1</span> -->
            </div>
        </div>


        <!-- cesium 地图组件 123{{  list }} -->
        <div id="cesiumContainer" style="height: 100%; width: 100%;"></div>


        <div v-if="isEchelonVisible">
            <!-- 轨迹详情-->
            <div class="echelonBox on" data-v-e6b6cb14="" @mouseover="isHovered = true" @mouseleave="isHovered = false"
                :style="{
                    backgroundColor: isHovered ? '#23222278' : 'rgba(255, 255, 255, 0.1)'
                }">
                <div class="closePlay2 main_flex_ar" :style="{
                    opacity: isHovered ? 1 : 0,
                    backgroundColor: isHovered ? '#ffffff66' : 'rgba(255, 255, 255, 0.1)'
                }">
                    <i class="el-icon" @click="closeEchelon">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                            </path>
                        </svg>
                    </i>
                </div>
                <div class="infoBoxTit main_flex">
                    <div class="titBoxImg">
                        <img style="width: 30px;height: 30px;margin-right: 15px" src="/mapGLB/tier.png" alt="轨迹详情">
                    </div>轨迹详情
                </div>
                <div class="homingTime">
                    <div style="margin-top: 5px;" id="currentTime_time">{{ EchelonTime }}</div>
                </div>


                <div>当前设备：{{ currentSelectDevice }}</div>
                <div>起点时间：{{ currentSelectDeviceStartTime }}</div>
                <div>终点时间：{{ currentSelectDeviceEndTime }}</div>
                <div>飞行时长：{{ currentSelectDeviceFlightduration }}</div>
                <hr>


                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <div>平均分速 (m/Min):</div>
                        <div>{{ averageSpeed }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <div>最高分速 (m/Min):</div>
                        <div>{{ maxSpeed }}</div>
                    </div>
                </div>

                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <div>平均高度 (m):</div>
                        <div>{{ averageAltitude }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <div>最大高度 (m):</div>
                        <div>{{ maxAltitude }}</div>
                    </div>
                </div>

                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <div>实际距离 (km):</div>
                        <div>{{ actualDistance }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <div>实际分速 (m/Min):</div>
                        <div>{{ actualSpeed }}</div>
                    </div>
                </div>

                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <div>直线距离 (km):</div>
                        <div>{{ lineDistance }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <div>直线分速 (m/Min):</div>
                        <div>{{ lineSpeed }}</div>
                    </div>
                </div>


                <hr>
                <!-- 时间
累积时间
距离
海拔  width="180"
速度 
06:52:04
00:02:04
0.62
122
1080
-->
                <el-table :data="tableData" :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }" height="250" border style="width: 100%">

                    <el-table-column label="分段资料">
                        <template slot-scope="scope">
                            {{ scope.$index + 1 }} <!-- $index 是从 0 开始的，所以加 1 以符合常规序号习惯 -->
                        </template>
                    </el-table-column>

                    <el-table-column label="时间">
                        <template slot-scope="scope">{{ formatGpsTime(scope.row.gpsTime) }}</template>

                    </el-table-column>


                    <el-table-column prop="accumulateDuration" label="累积时间">
                        <template slot-scope="scope">{{ formatDuration(scope.row.accumulateDuration) }}</template>

                    </el-table-column>
                    <el-table-column prop="accumulateOdometer" label="距离（m）">
                        <!-- calculateDistance -->
                        <!-- <template slot-scope="scope">
                            {{ calculateDistance(scope.row.lng,scope.row.lat) }}
                        </template>
    -->
                    </el-table-column>


                    <el-table-column prop="altitude" label="海拔（m）">
                    </el-table-column>

                    <el-table-column label="速度（m/s）">
                        <template slot-scope="scope">
                            {{ calculateSpeed(scope.row.accumulateOdometer, scope.row.accumulateDuration) }}
                        </template>
                    </el-table-column>


                </el-table>

            </div>
        </div>

        <div id="test" class="botOtherBox" v-if="true">
            <div class="botOtherItem"  @click="switchAnimation()">切换</div>
            <div class="botOtherItem"  @click="startAnimation()">播放</div>
            <div class="botOtherItem"  @click="pauseAnimation()">暂停</div>
            <div class="botOtherItem"  @click="resetAnimation()">重置</div>
            <div class="botOtherItem" @click="speedUpAnimation()">加速</div>
            <div class="botOtherItem"  @click="speedDownAnimation()">减速</div>

            <div class="playBox main_flex_ar">

                <div class="playBoxItem main_flex_be" @mouseover="showBox" @mouseleave="hideBox">
                    <div class="imgBox" @mouseover="showBox">
                        <img v-if="!isShowIoc"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAMAAABEH1h2AAAAq1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////Nr6iZAAAAOHRSTlMA8Yztth26zp1m4xNN9CJzLcTe+kbB6MoP0bSKMQrilpFhShanBK2YhW5pPxnafncqBtZTOCZaeo9UJeIAAALqSURBVEjH7ZbpkqIwEICNQYjcmHCKcooc3mfe/8k2OEyNos7i1Fbt7NZ8Pyyqu750hBzd+9skx/163H+J8Xp/TBpdJ2eUedwLeBk6E73RtdAW4YuIdqg1empjShXQHUWhFNtpoxsWpdA1OY7vBMeZLqTUMhpdgBQYgTbsjBYYgEKh0V2FWmnkDzrjR6lFFfe9OqDTeez0OuPE8ykFwrVOJt31CWnpIpKl7rokI/FH/5f0RFWlbRddUjf6MbkNHauoKGT19/pWjYu8f96rV7FBfE4N10Vk9rkuqb5cBPxuaaOh/raUZ4sNKettRqlw+lSfqUQLeHcKMFVE/qxfJrM55Zk9UijDlJ9P3lH9uAhMizYoZqEvBrqspTtMGdaOX/lPdakq5p4wAhfVspgB+fwUjTnXutijbLXXnYd6LG0XJOffCsOlnQXIZuMAO82WlIFF1wsjf/LkuxPHqXIm1Ey5cVT5ZGUqbP7WpbLiIi3WB8x+qscpZOVGtheWVcIOhcPKeBtOGdlZvj/Oeoyn+uTQX2KLD0viD5w67hwu9Sk02Hib5N1+8t97i32YBgUZNOG6vpaaBo/WZNBhzW8X/sFXnesz8VjJcnxQpa9vmdm32bA/+n+lK1+5oJXb+/2V9qDWwXVzkg19tTP+MLtqTmxIgY1WWmdW9TkG7UY3RUrhzjZNo4XZ0I7aO0ipaDY6Muo2T3kAuHAfr9tIAzV62Rcgpi+BodAvG31Dxrw7umM5EgFlAJE9tnD5Mdk0+jYhRf6g6R4jw8LUMtD4rl3PC5Jsb67jB8jBEuNlIN9nNmqHRbpYCxgL68XD5B/UZ9LgAVW4w3gXVneJhG2Q224iKoctouHKEzEWvRV7vKIso73vXO+gTTTnHrT9xhRTiqcGx7Uy6dr/qD9L9mgHQRsIFGYzXwGwlbG8U/KhL05oCQHsDID8h15fpSXieO4Oz/N4nv3cJbK8mty8uXU/fLTuwiAI2Zprk0fssv8G/AKn7ZGH2dOY0gAAAABJRU5ErkJggg=="
                            style="">
                        <img v-if="isShowIoc"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAMAAABEH1h2AAAAq1BMVEUAAAAa8v8Z8v8a8v8a8v8a8v8a7/8a8/8a8v8a8/8a8v8a8v8a8v8b8P8b9P8a8v8b8v8a8v8a8v8a8v8c7/8d//8Z8v8a8f8a8v8a8v8a8f8a8/8a8v8a8v8a8v8a8v8a8v8Y7P8a8v8a8v8a8/8a8v8W9P8a8v8a8v8a8v8a8v8Z8/8a8/8Z8/8c9v8Y8/8l//8a8v8a8/8a8v8a8v8a8v8Z8v8a8f8a8v9pH3kOAAAAOHRSTlMA8Izzts4euuNmnE0TIi3Ec9/6wQ8El0lE52/27NGfdzEK3MmQYRaniLOtfWk8GioG1lO8hSZaNcucUuQAAALtSURBVEjH7ZaJjpswEEDXAQIJISQGzH2EOxy5j/n/L6uXUi0l7Ja0VVtV+6QEa8zTIIPH8/K3Sa8nP588Re6frmmrLw3fK9inKDzfWLa6FUeCg57CEaLYavWMwwA2M15mbADMZa0umwCOq/CjUVwHwJRbnUOAZLKajWZFZKpwre4yYGZJMB1NkGQmMO637AwIc119GY2qzwVguI5OjM14fWOQnj4XF+P1hTj/1P+wrv2KrqWStDiM0LWFtF9eU60bW1yNxLJESfuhfpB0K5+UJ6njT3U/k13XMz7WNVUKRIucj2HkzZabr7Hd3qhIhACAu32oa5K+IrwrIAy2cPaXzcPsb3nBrW2gKOL7Ok2sW0QxocVWrGA3XYp+dsRAcY58Gby7dIvaIiy3ZhrVNKmBzpdbkrOu2djrojwt1UFdVw87/XL+mhiFUUG8CAG9KStCoGDT3cZJ0KzFoK7WORVeEdg8qQOjVBgA23Qw0IvrrfRgSu1h3VBVPaM2WnPbuKpTWhTqUkbwCrOOisvp2rzFd7Nv7pMQm3xcGcFUbWrSvckPjkxj+5TaH+iLl90pzohlTN+KUr3KFPns+cZ0xJY57II6kNRuTbzWoqjXkvrzO07T/pn9/qn/Z/ovHtDkufag0TvNSTG7S6O5z4puc4IARV5pWatRWFbpRZ3WSBFea2ikKHIPpaUfjY4OgKC0uifbALbNDIAoj1H79X7Za/VqwiEMT4ERN6lafa/nvBuu+4ShgICChDDsT7l8ru9b/ZAa1mWg6Z7MZRODI3t02ONiGenh7UXS43gAkYQYh0R8nNlLIz6Tnc9hzPm7wcnfqGuL6QBGfMT4GBsPE2mzQbrdRFLNeiSzcitgLGxLOuxQVckpUDupN/uEsGe+B8vLgg1gCzIddjjzbOYHmzc9Pc2PiEEPMBgo+HHGZG/pm767eSEawnGa32Oc7+rqvfJYnn1gu93yPP1j+xSXevPdyvmTeDJATMhQ/JLQw/4f4AsyV5EinEibCgAAAABJRU5ErkJggg==">
                    </div> 轨迹数据 <i class="el-icon icons"><svg viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z">
                            </path>
                        </svg></i>
                    <transition name="fade">
                        <div class="botBox" v-if="isBoxVisible" ref="botBox">
                            <div class="botBtnItem on" @click="showEchelonPopup">轨迹详情</div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// 所有鸽子数据
// import MyDataSource from '@/assets/data/datatest.json';

// 鸽子简单数据
//  import MyDataSource from '@/assets/data/test.json';

// 一只鸽子 新的数据源 867585332222480
// import MyDataSource from '@/assets/data/pigeonDataSource.json';

// 8 ok
import MyDataSource from '@/assets/data/pigeon_5.json';

// 15 ok
// import MyDataSource from '@/assets/data/pigeon_20.json';

// 30 ok
// import MyDataSource from '@/assets/data/pigeon_30.json';

// 引入ccs
import '@/assets/css/myload.css';

// 引入轨迹数据 全部鸽子
import TrajectoryData from '@/assets/data/datatest.json';


export default {
    props: ['coordinates', 'testData', 'colseload', 'sendisHide', 'sendShowPigeons', 'sendDeviceImei', 'sendDeviceImeiArray'],
    watch: {
        //         // TODO 监听 数据 变化
        //     coordinates(newVal, oldVal) {
        //       // 当 coordinates 发生变化时执行的操作
        //     //   console.log("轨迹数据coordinates:",newVal,oldVal);
        //       if (newVal) {
        //         // this.handleCoordinates(newVal);
        //         // this.initCesiumMap();
        //         // this.drawPolyline(newVal);
        //       }
        //     },
        // testData(newVal, oldVal) {
        //        // 当 coordinates 发生变化时执行的操作
        // //   console.log("发生变化时执行的操作轨迹数据coordinates:",newVal,oldVal);
        // //   if (newVal) {
        // //     // this.handleCoordinates(newVal);
        // //     // this.initCesiumMap();
        // //     this.drawPolyline(newVal);
        //   },


        //     }
        // TODO 监听 数据 变化
        sendDeviceImeiArray(newVal, oldVal) {
            // console.log("sendDeviceImeiArray",newVal.length,"监听 数据 变化:",newVal);
            this.hideShowDevice(newVal);

        },
        // 隐藏实体 移除实体
        sendisHide(newVal, oldVal) {
            // console.log("sendisHide隐藏实体 移除实体:",newVal,oldVal);
            // true_mowg5psm76
            // this.hideShowDevice(newVal);
            // if(newVal){
            //     this.removeAllEntity();
            // }
            this.removeAllEntity();

        },

        // 显示实体
        sendShowPigeons(newVal, oldVal) {
            this.showAllEntity();
            // console.log("显示实体:",newVal,oldVal);
            // if(newVal){
            //     this.showAllEntity();
            // }

        },
        // TODO 拿到点击的设备号满
        sendDeviceImei(val, oldVal) {


            //   let imei = ; // 假设id格式为'point_IMEI'
            // 创建方法 通过设备号 找数数据 TrajectoryData
            this.findDevicePigeonData(val.split('_')[0]);
            // }
            // console.log(oldVal,"轨迹数据sendDeviceImei:",val);
            // this.findDevicePosition(val);
        }

    },
    data() {
        return {
            isBoxVisible: false, // 样式是否显示弹框
            isHovered: false,
            isEchelonVisible: false, // 样式是否显示弹框
            isShowIoc: false, // 样式是否显示弹框
            EchelonTime: "", // 当前时间


            currentSelectDevice: "",// 当前选中的设备
            currentSelectDeviceStartTime: "",// 当前选中的设备起点时间
            currentSelectDeviceEndTime: "",// 当前选中的设备终点时间
            currentSelectDeviceFlightduration: "",// 当前选中的设备飞行时长

            // 平均分速
            averageSpeed: 0, // 平均速度
            // 最高分速
            maxSpeed: 0, // 最高速度
            averageAltitude: 0, // 平均海拔
            // 最大高度
            maxAltitude: 0, // 最大高度
            // 实际距离
            actualDistance: 0, // 实际距离
            // 实际分速
            actualSpeed: 0, // 实际速度
            // 直线距离
            lineDistance: 0, // 直线距离
            // 直线分速
            lineSpeed: 0, // 直线速度



            echelonData_0_20: 0, // 20公里以下  
            echelonData_20_35: 0, // 
            echelonData_35_50: 0, // 35公里以上
            echelonData_50_65: 0, // 50公里以上
            echelonData_65_80: 0, // 65公里以上
            echelonData_80: 0, // 80公里以上

            circleRadius_30: 20000.0, // 米作为单位，这里 红
            circleRadius_100: 35000.0, // 使用米作为单位，这里 蓝色 
            circleRadius_200: 50000.0, // 使用米作为单位，这里 绿色
            circleRadius_300: 65000.0, // 半径为使用米作为单位，这里 紫色
            circleRadius_400: 80000.0, // 半径，这里 黄

            circleCenter_30: 0,
            circleCenter_100: 0,
            circleCenter_200: 0,
            circleCenter_300: 0,
            circleCenter_400: 0,



            statisticsInterval: null, // 用于存储定时器的引用


            eventStartTime: 0, //   开始时间
            eventEndTime: 0,  // 结束时间
            eventName: '',  // 赛事名称
            currentIndex: -1, // 切换实体当前索引

            startLat: 0,  // 开始纬度
            startLng: 0,  // 开始经度
            endLat: 0,  // 结束纬度
            endLng: 0,  // 结束经度

            startTime: 0,  // 开始时间
            stopTime: 0,  // 结束时间
            viewer: null,

            // 用于存储已生成的颜色
            generatedColors: new Set(),
            // 存储当前唯一的颜色
            uniqueColor: '',

            mLatLng: [],
            //    id:this.list[index][0].deviceImei+"--->"+this.list[index][0].deviceName,
            // TODO 全部鸽子 TrajectoryData   ， 自定义鸽子 MyDataSource
            list: MyDataSource,
            tableData: [],
            list2:
                [
                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        deviceImei: "1234567890",
                        deviceName: "测试1",
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                    },
                    {
                        lng: 116.2821,
                        lat: 39.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699375800000,
                    },
                    {
                        lng: 115.497402,
                        lat: 39.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699376900000,
                    },],

                    // 2 
                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                        deviceImei: "8888888",
                        deviceName: "测试2",
                    },
                    {
                        lng: 117.3821,
                        lat: 38.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699378800000,
                    },
                    {
                        lng: 118.507402,
                        lat: 38.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699374800000,
                    },],
                    // 3

                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                        deviceImei: "999999",
                        deviceName: "鸽子3",
                    },
                    {
                        lng: 117.2821,
                        lat: 38.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699376800000,
                    },
                    {
                        lng: 118.497402,
                        lat: 38.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699377800000,
                    },],



                ],
            // * accumulateOdometer: 累计里程，表示设备自某个起始点以来的总行驶距离。
            // * accumulateDuration: 累计持续时间，可能表示设备总的工作或活动时间长度。
            //速度  = 里程 / 时间 ;   上一个点距离/上一个点时间 = 速度
            tableData2: [{
                index: 1,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4049,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
            },
            {
                index: 2,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4500,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
            },
            {
                index: 3,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4500,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
            },
            {
                index: 4,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4500,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
            },
            {
                index: 5,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4500,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
            },
            {
                index: 6,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: '4500',
                altitude: '25', // 海拔高度
                odometer: '600',
                duration: '6',
                speed: '0',
            },

            ],

        };
    },
    // 要关闭的时候
    beforeDestroy() {
        // 销毁地图
        if (window.viewer) {

            // 移除监听器
            window.viewer.clock.onTick.removeEventListener(this.mOnTick);

            window.viewer.entities.removeAll(); // 移除所有实体
            window.viewer = null;

        }



    },

    // destroyed() {
    //     if (window.viewer) {
    //         window.viewer.entities.removeAll(); // 移除所有实体
    //         window.viewer = null;
    //     }
    // },
    created() {
        this.$i18n.locale = window.sessionStorage.getItem('lang')
        // this.userId = window.sessionStorage.getItem('userId')
        // this.userName = window.sessionStorage.getItem('userName')
        // this.pwdType = window.sessionStorage.getItem('pwdType')

        //  TODO 从缓存获取赛事名称 开始 和结束
        this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || ''
        this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
        this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || ''

        console.log("开始时间：", this.eventStartTime, "结束时间：", this.eventEndTime);

        this.startLat = window.sessionStorage.getItem('historicalTrackStarLat') || '0'
        this.startLng = window.sessionStorage.getItem('historicalTrackStarLon') || '0'
        this.endLng = window.sessionStorage.getItem('historicalTrackEndLon') || '0'
        this.endLat = window.sessionStorage.getItem('historicalTrackEndLat') || '0'

        console.log("开始经纬度：", this.startLng, "开始经纬度：", this.startLat);
        console.log("结束经纬度：", this.endLng, "结束经纬度：", this.endLat);

        this.startTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime)); // 中国时间 开始时间
        this.stopTime = Cesium.JulianDate.fromDate(new Date(this.eventEndTime));  // 中国时间 结束时间 1730981637000

        // 查看上面数据
        console.log("开始时间：", this.startTime, "结束时间：", this.stopTime);

        // console.log("开始时间：",new Date(this.eventStartTime),"结束时间：",new Date(this.eventEndTime))


        // console.log('地图了----赛事信息:', this.eventName,this.eventStartTime,this.eventEndTime)

        // try {
        //   const response = await fetch('/data/datatest.json');
        //   const data = await response.json();
        //   this.list = data;
        // } catch (error) {
        //   console.error('Error loading data:', error);
        // }
    },

    mounted() {
        // 初始化时生成一个唯一颜色
        this.generateUniqueColor();
        // console.log("轨迹数据2:",this.mLatLng2.flat());
        // console.log("轨迹数据:",this.mLatLng.flat());
        // cesium 地图初始化
        this.initCesiumMap();
        // 添加模型
        // this.addModel( '/mapGLB/Cesium_Air.glb',5000);
        // this.addModel('/mapGLB/pigeon1.glb', 5000);
        // console.log("闯过的指轨迹数据coordinates:",this.coordinates);
        // console.log("闯过的指轨迹数据test:",this.test);



    },
    methods: {
        // TODO 样式
        showBox() {
            this.isBoxVisible = true;
            this.isShowIoc = true;
            // 可选：调整botBox的位置以正好显示在“数据”上方
            this.$nextTick(() => {
                const botBox = this.$refs.botBox;
                // 这里可以根据需要添加逻辑来调整botBox的样式，比如top和left属性
                // 注意：由于botBox使用了绝对定位，你可能需要为其父元素设置相对定位（position: relative;）
            });
        },
        hideBox() {
            this.isBoxVisible = false;
            this.isShowIoc = false;
        },
        showEchelonPopup() {
            this.isEchelonVisible = true;
            // console.log("echelonPopup")
        },
        closeEchelon() {
            this.isEchelonVisible = false;
        },
        //  结束样式
        // TODO 轨迹数据
        // TODO 计算速度 = (当前位置-上一个位置) / (当前时间 - 上一个时间);
        calculateSpeed(odometer, duration) {
            // 确保 odometer 不为空，以避免除以0的错误
            if (!odometer || odometer === 0) {
                return 0;
            }
            // 确保duration不是0，以避免除以0的错误
            return duration === 0 ? 0 : (odometer / duration * 60).toFixed(2); // 保留两位小数
        },
        // 格式化GPS时间
        formatGpsTime(gpsTime) {
            const [date, time] = gpsTime.split(' ');
            return time;
        },
        // 格式累积时间
        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            seconds %= 3600;
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
        },
        padZero(number) {
            return number.toString().padStart(2, '0');
        },

        // 计算距离 起点 和 当前位置的距离
        calculateDistance(nowLng, nowEnd) {
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(nowLng, nowEnd);
            // 计算距离 m 换成km 保留2位小数
            // return  Cesium.Cartesian3.distance(startPosition,endPosition).toFixed(2);
            return (Cesium.Cartesian3.distance(startPosition, endPosition) / 1000).toFixed(2);
        },
        // 表格轨迹数结束


        // 绘制轨迹线
        drawPolyline(coordinates) {
            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < coordinates.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < coordinates[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lon, lat] = coordinates[i][j];
                    positions.push(Cesium.Cartesian3.fromDegrees(coordinates[i][j].lng, coordinates[i][j].lat));
                }
                // 使用positions数组创建polyline实体，并添加到viewer中
                window.viewer.entities.add({
                    polyline: {
                        positions: positions, // 使用转换后的Cartesian3对象数组
                        width: 5,
                        material: Cesium.Color.fromCssColorString(this.getRandomColor()),
                    }
                });
            }

            //           // 绘制线
            // var dataSource = new Cesium.CustomDataSource("linedata");
            //             window.viewer.dataSources.add(dataSource);
            //             dataSource.entities.add({
            //                 polyline: {
            //                     name: "line",

            //                     positions: positions,
            //                     width: 5,
            //                     material: Cesium.Color.fromCssColorString("#00ff00"),

            //                 }
            //             });

            //     window.viewer.zoomTo(dataSource)
        },
        // TODO 初始化地图
        initCesiumMap() {
            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");
            // 张总 eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YWFiNmJiMS1jNjRjLTRkM2QtYTgzOC05MTMwZDJjOGJmNzIiLCJpZCI6MjU2MDE3LCJpYXQiOjE3MzIwMTgzMDl9.N5fr5-Z7OGnVoirhJ-wA3NzQ4TFDidiw9cUzlQBZGKc
            //      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg
            window.viewer = new Cesium.Viewer("cesiumContainer", {
                // 优化

                // requestRenderMode : true, // 开启渲染模式
                // maximumRenderTimeChange : Infinity, // 最大渲染时间


                homeButton: true,
                sceneModePicker: true,  // 地图模式
                baseLayerPicker: false, // 影像切换
                animation: true, // 是否显示动画控件
                infoBox: false, // 是否显示点击要素之后显示的信息
                selectionIndicator: false, // 要素选中框
                geocoder: false, // 是否显示地名查找控件
                timeline: true, // 是否显示时间线控件
                fullscreenButton: true, // 是否显示全屏控件
                shouldAnimate: false,   // 默认不播放动画
                navigationHelpButton: false, // 是否显示帮助信息控件
                creditContainer: document.createElement("div"),
                infoBox: true, // 是否显示点击要素之后显示的信息
                // 最小缩放




                // 地形 ok
                // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                //   地形 ok
                terrain: Cesium.Terrain.fromWorldTerrain({
                    requestWaterMask: false, // 水面蒙版
                    requestVertexNormals: true  //
                }),

                // 影像 imageryProvider
                // imageryProvider: new Cesium.SingleTileImageryProvider({
                //     url: require("../../assets/images/map/1.jpg"),
                // }),



            });

            //             var tileset = new Cesium.Cesium3DTileset({
            //     url: Cesium.IonResource.fromAssetId(5741),
            //     maximumScreenSpaceError: 24
            // });
            // 显示地图阴影
            // window.viewer.shadows= true;
            // window.viewer.scene.globe.enableLighting = true;
            // 显示帧率组件
            window.viewer.scene.debugShowFramesPerSecond = true;
            //  TODO 优化性能
            // window.viewer.scene.requestRenderMode = true;   // 开启渲染模式 加速
            // window.viewer.scene.debugShowFramesPerSecond = true;
            // window.viewer.resolutionScale = 0.9; // 默认值为 1.0；

            // window.viewer.scene.msaaSamples = 4 // 抗锯齿
            // window.viewer.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿
            // window.viewer.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // viewer.resolutionScale = window.devicePixelRatio; // 分辨率

            // Hides the stars
            window.viewer.scene.skyBox.show = false;
            // Explicitly render a new frame
            window.viewer.scene.requestRender();

            // 绘制起点终点
            // TODO 绘制贴地线   lng: 116.405419,
            // lat: 39.918034,   lng: 118.497402,
            // lat: 38.344641,
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);
            this.createGroundLineAndBillboards(startPosition, endPosition);




            // 计算距离
            // var distance=Cesium.Cartesian3.distance(startPosition,endPosition)

            // console.log( "计算直线距离 两点距离" ,distance)

            // console.log("测试开始时间--->:",new Date(1694448065000));


            // window.viewer.zoomTo(circleEntity);

            // this.drawPolyline(this.list);

            // TODO 通过 ID 获取实体
            // const dynamicEntity = window.viewer.entities.getById("enclosure_30");
            // console.log("绘制一个中心透明获取的实体", dynamicEntity.position);


            // TODO 调用模型动画 
            // ./mapGLB/gezia.glb

            // this.modelAnimate(this.list,'/mapGLB/pigeon1.glb');
            // this.modelAnimate(this.list,'./mapGLB/gezia.glb');
            // this.modelAnimate(this.list,'./mapGLB/gezib.glb');
            // 去脚 pigeon_qujiao.glb
            this.modelAnimate(this.list, './mapGLB/pigeon_qujiao.glb');
            // pigeonX 不行
            // this.modelAnimate(this.list, './mapGLB/pigeonX.glb');
            // this.modelAnimate(this.list, './mapGLB/pegeon_yss.glb');

            // 自定义主页按钮
            // 保存当前上下文的viewer引用
            // var viewerRef = window.viewer;
            // TODO 自定义主页按钮
            // window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(function (e) {
            //     e.cancel = true;
            //     // 你要飞的位置
            //     viewerRef.camera.flyTo({ // ( this.startLng, this.startLat);
            //         destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 5000.0),
            //         duration: 1,	// 以秒为单位的飞行持续时间。
            //         offset : {
            //             heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //             pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //             range: 10								// 到中心的距离，以米为单位。								
            //         }
            //     });
            // });
            window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                // 你要飞的位置
                window.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000.0),
                    duration: 1, // 以秒为单位的飞行持续时间。
                    offset: {
                        heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                        pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                        range: 10								// 到中心的距离，以米为单位。								
                    }
                });
            });


            // 如果需要叠加高德/百度注记地图则添加以下代码
            window.viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                layer: "tdtAnnoLayer",
                style: "default",
                format: "image/jpeg",
                tileMatrixSetID: "GoogleMapsCompatible"
            }))

            // 自定义的近地天空盒 近景天空
            let groundSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/lantian/Left.jpg'),
                    positiveX: require('@/assets/skyBox/lantian/Right.jpg'),
                    negativeY: require('@/assets/skyBox/lantian/Back.jpg'),
                    positiveY: require('@/assets/skyBox/lantian/Front.jpg'),
                    negativeZ: require('@/assets/skyBox/lantian/Down.jpg'),
                    positiveZ: require('@/assets/skyBox/lantian/Up.jpg')
                }
            })


            // 自带的默认天空盒
            // let defaultSkybox = window.viewer.scene.skyBox;
            // 自定义的近地天空盒 远处 天空
            let defaultSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/5/tycho2t3_80_mx.jpg'),
                    positiveX: require('@/assets/skyBox/5/tycho2t3_80_px.jpg'),
                    negativeY: require('@/assets/skyBox/5/tycho2t3_80_my.jpg'),
                    positiveY: require('@/assets/skyBox/5/tycho2t3_80_py.jpg'),
                    negativeZ: require('@/assets/skyBox/5/tycho2t3_80_mz.jpg'),
                    positiveZ: require('@/assets/skyBox/5/tycho2t3_80_pz.jpg')
                }
            })
            // 渲染前监听并判断相机位置
            window.viewer.scene.preUpdate.addEventListener(() => {
                let position = window.viewer.scene.camera.position;
                let cameraHeight = Cesium.Cartographic.fromCartesian(position).height;
                if (cameraHeight < 240000) {
                    window.viewer.scene.skyBox = groundSkybox;
                    window.viewer.scene.skyAtmosphere.show = false;
                } else {
                    window.viewer.scene.skyBox = defaultSkybox;
                    window.viewer.scene.skyAtmosphere.show = true;
                }
            })





            // 立即设置时间格式化函数
            window.viewer.animation.viewModel.dateFormatter = this.CesiumDateFormatter;
            window.viewer.animation.viewModel.timeFormatter = this.CesiumTimeFormatter;
            Cesium.Timeline.prototype.makeLabel = this.CesiumDateTimeFormatter;

            // 设置始时钟始时间
            window.viewer.clock.startTime = this.startTime.clone();

            // const utcDate = new Date(Date.UTC(2023, 10 - 1, 8, 0, 33, 57)); // 月份是从 0 开始的，所以 10 表示 11 月
            // // 将 JavaScript Date 对象转换为 Cesium 的 JulianDate 对象
            // const julianDate = Cesium.JulianDate.fromDate(utcDate);  2023-11-08 08:32:00
            let currentTime = Cesium.JulianDate.fromDate(new Date(1699403520000)); // 中国时间 开始时间
            //  TODO 设置时钟当前时间 改了下
            // window.viewer.clock.currentTime = this.startTime.clone();
            // 设置指定时间  Cesium Viewer 的当前时间为这个 JulianDate
            window.viewer.clock.currentTime = currentTime.clone();


            // 时间速率，数字越大时间过的越快
            window.viewer.clock.multiplier = 10;
            window.viewer.clock.stopTime = this.stopTime.clone();

            // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // 时间轴（使用第一个轨迹的开始和停止时间）
            window.viewer.timeline.zoomTo(this.startTime, this.stopTime);

            // 循环执行，到达终止时间，重新从起点时间开始
            window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;


            // var circleRadius_30 =  20000.0; // 米作为单位，这里 红
            // var circleRadius_100 = 35000.0; // 使用米作为单位，这里 蓝色 
            // var circleRadius_200 = 50000.0; // 使用米作为单位，这里 绿色
            // var circleRadius_300 = 65000.0; // 半径为使用米作为单位，这里 紫色
            // var circleRadius_400 = 80000.0; // 半径，这里 黄

            // TODO 绘制实体圆圈
            this.createCircleEntity(endPosition, this.circleRadius_30, 'enclosure_30', "#ff0000");
            this.createCircleEntity(endPosition, this.circleRadius_100, 'enclosure_100', "#0000ff");
            this.createCircleEntity(endPosition, this.circleRadius_200, 'enclosure_200', "#00ff00");
            this.createCircleEntity(endPosition, this.circleRadius_300, 'enclosure_300', "#ff00ff");
            this.createCircleEntity(endPosition, this.circleRadius_400, 'enclosure_400', "#ffff00");

            // 通过 ID 获取实体
            const dynamicEntity_30 = window.viewer.entities.getById("enclosure_30");
            const dynamicEntity_100 = window.viewer.entities.getById("enclosure_100");
            const dynamicEntity_200 = window.viewer.entities.getById("enclosure_200");
            const dynamicEntity_300 = window.viewer.entities.getById("enclosure_300");
            const dynamicEntity_400 = window.viewer.entities.getById("enclosure_400");

            // console.log("绘制一个中心透明获取的实体position", dynamicEntity.position.getValue());

            this.circleCenter_30 = dynamicEntity_30.position.getValue();
            this.circleCenter_100 = dynamicEntity_100.position.getValue();
            this.circleCenter_200 = dynamicEntity_200.position.getValue();
            this.circleCenter_300 = dynamicEntity_300.position.getValue();
            this.circleCenter_400 = dynamicEntity_400.position.getValue();

            //TODO 调用绘制广告牌
            //  this.createIntersectionBillboards(this.startLng, this.startLat,this.endLng,  this.endLat,this.endLng,  this.endLat,circleRadius_30);

            // TODO 点击事件

            // var handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);
            // handler.setInputAction((click) => {
            //     console.log('左键单击事件：', click.position);

            //     // 获取当前时间
            //     var currentTime = window.viewer.clock.currentTime;
            //     console.log("当前时间按-》", this.CesiumDateTimeXFormatter(currentTime));

            //     //   // 获取当前时间（ISO 8601 格式） 2023-11-08T00:30:00Z
            //     //     console.log("当前时间按-》",Cesium.JulianDate.toIso8601(currentTime));
            //     //     // 将 JulianDate 转换为 Date 对象（注意：这将基于 UTC 时间）
            //     //     var date = Cesium.JulianDate.toDate(currentTime);

            //     //     // 创建一个选项对象来指定日期时间格式
            //     //     var options = {
            //     //         year: 'numeric', month: '2-digit', day: '2-digit',
            //     //         hour: '2-digit', minute: '2-digit', second: '2-digit',
            //     //         hour12: false, // 使用 24 小时制
            //     //         timeZoneName: 'short' // 这将显示时区名称，但我们可以稍后移除它
            //     //     };

            //     //     // 使用 Intl.DateTimeFormat 来格式化日期时间
            //     //     var formatter = new Intl.DateTimeFormat('zh-CN', options);
            //     //     var formattedParts = formatter.formatToParts(date);

            //     //     // 手动构建你想要的字符串格式，不考虑时区名称
            //     //     var formattedDate = '';
            //     //     formattedParts.forEach(part => {
            //     //         if (part.type !== 'timeZoneName') {
            //     //             formattedDate += part.value;
            //     //             if (['hour', 'minute', 'second'].includes(part.type) && formattedDate[formattedDate.length - 1] !== ':') {
            //     //                 // 在小时、分钟和秒之间添加空格（如果它们不是以冒号结尾的话）
            //     //                 formattedDate += '';
            //     //             }
            //     //         }
            //     //     });

            //     //     console.log('当前时间按-》', formattedDate);





            //     var ray = window.viewer.camera.getPickRay(click.position);
            //     var position = window.viewer.scene.globe.pick(ray, window.viewer.scene);
            //     if (position) {
            //         console.log('位置信息position：', position);
            //         console.log('位置信息：', Cesium.Cartographic.fromCartesian(position));
            //         // var cartographicPositions = Cesium.Ellipsoid.WGS84.cartesianArrayToCartographicArray(position);
            //         // console.log('WGS848经纬度信息：x , y , z ',cartographicPositions);
            //     }
            // }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            // TODO 绘制公里
            window.viewer.entities.add({
                position: { x: -2967602.291477882, y: 5042542.63032606, z: 2530832.860173073 },
                // 广告牌
                label: {
                    text: '20公里',
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    // outlineColor: Cesium.Color.BLACK, // 轮廓线
                    // outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                    // font: '20px Helvetica',
                    // font: '14px sans-serif',
                    // fillColor: Cesium.Color.RED, 
                    // outlineWidth: 2,
                    // outlineColor: Cesium.Color.BLACK
                },
            });

            //35   {x: -2968322.081354647, y: 5035404.077140281, z: 2543951.1493177502}

            window.viewer.entities.add({
                position: { x: -2968322.081354647, y: 5035404.077140281, z: 2543951.1493177502 },
                // 广告牌
                label: {
                    text: '35公里',
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });
            //50  {x: -2969523.8139385805, y: 5023475.059736933, z: 2565870.680219716}
            window.viewer.entities.add({
                position: { x: -2969051.4843325014, y: 5028271.745112436, z: 2557093.697250429 },
                // 广告牌
                label: {
                    text: '50公里',
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });

            // 65
            window.viewer.entities.add({
                position: { x: -2969759.160827037, y: 5021091.037034286, z: 2570234.437052611 },
                // 广告牌
                label: {
                    text: '65公里',
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });


            // 80
            window.viewer.entities.add({
                position: { x: -2970453.47114406, y: 5013898.453903222, z: 2583347.383230127 },
                // 广告牌
                label: {
                    text: '80公里',
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                    pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                },
            });

            // 在 400 里面 大于300 的 小于 400的  是黄色 
            // 300 里面 大于200 的 小于 300的  是紫色
            // 200 里面 大于100 的 小于 200的  是绿色
            // 100 里面 大于30 的 小于 100的  是蓝色
            // 小于30 是红色
            // 其它是白色

            // TODO 这里 111啊 监听时钟变化
            // const _this = this;
            window.viewer.clock.onTick.addEventListener(this.mOnTick);



            // 确保时间轴更新
            // window.viewer.timeline.updateFromClock();
            // window.viewer.timeline.zoomTo(window.viewer.clock.startTime, window.viewer.clock.stopTime);

            // // 最小缩放高度（米）
            window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 300;
            // // 最大缩放高度（米）
            // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 500000;

            // 鼠标滚轮控制视角
            window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];

        },

        // TODO 封装方法 绘制贴地线
        createGroundLineAndBillboards(startPosition, endPosition) {
            // 创建贴地线
            var instance = new Cesium.GeometryInstance({
                geometry: new Cesium.GroundPolylineGeometry({
                    positions: [
                        startPosition,
                        endPosition
                    ],
                    width: 4.0
                }),
                id: '贴地线'
            });

            window.viewer.scene.groundPrimitives.add(new Cesium.GroundPolylinePrimitive({
                geometryInstances: instance,
                appearance: new Cesium.PolylineMaterialAppearance({
                    material: Cesium.Material.fromType('#FF00ff')
                })
            }));

            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            window.viewer.camera.flyTo({
                //   lon: 116.405419,
                // lat: 39.918034,
                // fromDegrees()方法，将经纬度和高程转换为世界坐标，这里定位到中国  101.8, 33.74,  121.795693, 25.146142
                //                     heading-代表镜头左右方向,正值为右,负值为左,360度和0度是一样的
                // pitch-代表镜头上下方向,正值为上,负值为下.
                // roll-代表镜头左右倾斜.正值,向右倾斜,负值向左倾斜、
                // Cesium.Cartesian3.fromDegreesArrayHeights( [this.startLng, this.startLat,5000,this.endLng,  this.endLat, 5000]),
                //   let startPosition = Cesium.Cartesian3.fromDegrees( this.startLng, this.startLat);
                // let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng,  this.endLat);

                destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000),
                // orientation: {
                //     // 指向 z 180
                //     // heading:Cesium.Math.toRadians(-10),
                //     // 视角 y
                //     pitch:Cesium.Math.toRadians(-90),
                //     // 视角 x
                //     roll: -3.0,
                // },
                duration: 1,	// 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }
            });

            // 在起点添加广告牌
            window.viewer.entities.add({
                position: startPosition,
                // 根据所提供的速度计算模型的朝向
                // orientation: new Cesium.VelocityOrientationProperty(startPosition),
                // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                model: {
                    uri: './mapGLB/start2.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    // scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.BOTTOM, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向

                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 

                },

                // billboard: {
                //     image: require('@/assets/mapGLB/start6.png'),
                //     // scale : 1.5,       // 大小
                //     // alignedAxis: Cesium.Cartesian3.UNIT_Z, // 设置广告牌始终面向相机
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考

                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                label: {
                    text: '起点',
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    // scale : 1.5,       // 大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Material.fromType('#00ff00'),
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -120), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });


            // // 在终点添加广告牌
            window.viewer.entities.add({
                id: 'end',
                position: endPosition,
                // 根据所提供的速度计算模型的朝向
                //  orientation: new Cesium.VelocityOrientationProperty(endPosition),
                // // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                model: {
                    uri: './mapGLB/end3.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    // scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画

                },
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                //     scale : 1.5,       // 大小
                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                label: {
                    text: '终点',

                    scale: 1.5,       // 大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.RED,
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -120), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });
        },
        // TODO 方法 绘制圈
        createCircleEntity(position, radius, id, color) {
            var circleEntity = window.viewer.entities.add({
                id: id,
                position: position, // 设置圆心位置
                name: id, // 使用ID作为实体名称
                ellipse: {
                    semiMinorAxis: radius, // 设置椭圆的短半轴，与长半轴相等，形成圆
                    semiMajorAxis: radius, // 设置椭圆的长半轴
                    extrudedHeight:1000, // 设置高度
                    // height: 0, // 设置高度为0，使其贴合地面
                    material: Cesium.Color.fromCssColorString('#ffffff00'),// 设置填充颜色为绿色但完全透明

                    outline: true, // 启用外边框
                    // 可以根据需要设置outlineColor和outlineWidth，或者作为额外参数传入
                    outlineColor: Cesium.Color.fromCssColorString(color), // 默认设置外边框颜色为红色
                    outlineWidth: 2 // 默认设置外边框宽度为2
                }
            });
            return circleEntity; // 返回创建的实体对象，以便后续操作（可选）
        },

        // TODO 这里 根据设备号查找设备位置
        findDevicePosition(deviceImei) {
            // 获取所有鸽子实体
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_' + deviceImei);
            });


            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                console.log("没有找到设备" + deviceImei);
                return;
            }

            // 更新当前索引并获取当前选中的实体
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;
            const targetEntity = pointEntities[this.currentIndex];

            // 如果存在上一个实体，则将其标签颜色设置为白色
            if (this.previousEntity && this.previousEntity.label) {
                this.previousEntity.label.fillColor = Cesium.Color.WHITE;
            }

            // 检查目标实体是否存在并更新其标签颜色
            if (Cesium.defined(targetEntity) && targetEntity.label) {
                targetEntity.label.fillColor = Cesium.Color.RED;
            } else {
                console.warn('目标实体不存在或其标签未定义。');
            }

            // 更新上一个实体的引用
            this.previousEntity = targetEntity;

            // 切换到目标实体
            window.viewer.trackedEntity = targetEntity;

        },

        // TODO 根据设备号查找鸽子轨迹数据展示数据到大屏
        findDevicePigeonData(deviceImei) {
            //    console.log('查找设备'+deviceImei); 
            //    console.log('查找设备'+TrajectoryData.length); 
            // 当期设备
            this.currentSelectDevice = deviceImei;

            this.tableData = TrajectoryData.find((logGroup) =>
                logGroup.some((log) => log.deviceImei === deviceImei)
            );

            if (this.tableData) {
                const length = this.tableData.length;
                // console.log('找到设备'+this.tableData[0].deviceImei);
                this.currentSelectDeviceStartTime = this.tableData[0].gpsTime;
                this.currentSelectDeviceEndTime = this.tableData[length - 1].gpsTime;
                this.currentSelectDeviceFlightduration = this.formatDuration(this.tableData[length - 1].accumulateDuration);

                let totalDistance = 0; // 累计里程
                let totalDuration = 0;  // 累计时长
                let maxDistance = 0;


                // 平均分速
                // averageSpeed: 0, // 平均速度
                this.tableData.forEach(log => {
                    // 累计里程和时长（这里假设每个日志都是一个新的行程开始和结束）
                    // 注意：这里的计算方式可能并不准确，因为缺少每个行程的开始时间和里程
                    totalDistance += log.accumulateOdometer; // 这可能是从起点到这里的累计里程
                    totalDuration += log.accumulateDuration; // 这可能是从起点到这里的累计时长（单位可能是秒）


                });
                this.averageSpeed = (totalDistance / totalDuration * 60).toFixed(2);
                // // 最高分速
                // maxSpeed: 0, // 最高速度
                // 计算基于假设的最高分速
                //  "accumulateOdometer": 3609,
                // "accumulateDuration": 141,
                // this.maxSpeed = Math.max(...this.tableData.map(log => {
                //     return ((log.accumulateOdometer / log.accumulateDuration )* 60).toFixed(2) ;
                // }));

                // 优化 数据可能为空
                this.maxSpeed = Math.max(...this.tableData.map(log => {
                    const odometer = log.accumulateOdometer || 0; // 如果为空或未定义，则默认为0
                    const duration = log.accumulateDuration || 1; // 防止除以零，如果未定义则默认为1（或选择其他合适的值）
                    const speed = (odometer > 0 && duration > 0) ? ((odometer / duration) * 60).toFixed(2) : '0.00';
                    return parseFloat(speed); // 返回浮点数用于Math.max计算，因为toFixed返回的是字符串
                }));

                // averageAltitude: 0, // 平均海拔
                // // 最大高度
                // maxAltitude: 0, // 最大高度
                // // 实际距离
                // actualDistance: 0, // 实际距离
                let total = 0;
                let totalTime = 0;
                for (let i = 1; i < this.tableData.length; i++) {
                    const currentOdometer = this.tableData[i].accumulateOdometer;
                    const previousOdometer = this.tableData[i - 1].accumulateOdometer;
                    // 总距离 = 后一个 - 上一个
                    total += currentOdometer - previousOdometer;

                    // 总时长
                    const currentDuration = this.tableData[i].accumulateDuration;
                    const previousDuration = this.tableData[i - 1].accumulateDuration;
                    totalTime += currentDuration - previousDuration;
                }

                this.actualDistance = (total / 1000).toFixed(2);
                // // 实际分速
                // actualSpeed: 0, // 实际速度 
                this.actualSpeed = (total / totalTime * 60).toFixed(2);

                // 直线距离 起点经纬度 到 终点 经纬度 距离
                // lineDistance: 0, // 直线距离
                let startPosition = Cesium.Cartesian3.fromDegrees(this.tableData[0].lng, this.tableData[0].lat);
                let endPosition = Cesium.Cartesian3.fromDegrees(this.tableData[length - 1].lng, this.tableData[length - 1].lat);
                let resultDistance = Cesium.Cartesian3.distance(startPosition, endPosition)
                this.lineDistance = (resultDistance / 1000).toFixed(2);
                // // 直线分速
                // lineSpeed: 0, // 直线速度
                this.lineSpeed = (resultDistance / totalTime * 60).toFixed(2);
                // 平均海拔高度
                const totalAltitude = this.tableData.reduce((sum, log) => sum + log.altitude, 0);
                this.averageAltitude = (totalAltitude / length).toFixed(2);
                // 最大高度
                this.maxAltitude = Math.max(...this.tableData.map(log => log.altitude));



            } else {
                // 没有找到设备 清空之前的数据
                // this.tableData = [];
                this.currentSelectDeviceStartTime = '';
                this.currentSelectDeviceEndTime = '';
                this.currentSelectDeviceFlightduration = '';

                this.averageSpeed = 0;
                this.maxSpeed = 0;
                this.averageAltitude = 0;
                this.maxAltitude = 0;
                this.actualDistance = 0;
                this.actualSpeed = 0;
                this.lineDistance = 0;
                this.lineSpeed = 0;
                // averageAltitude: 0, // 平均海拔
                // // 最大高度
                // maxAltitude: 0, // 最大高度
                // // 实际距离
                // actualDistance: 0, // 实际距离
                // // 实际分速
                // actualSpeed: 0, // 实际速度
                // // 直线距离
                // lineDistance: 0, // 直线距离
                // // 直线分速
                // lineSpeed: 0, // 直线速度
            }

        },
        // TODO 方法 隐藏显示设备
        hideShowDevice(deviceImeiArray) {

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 先把所有的隐藏
            pointEntities.forEach(entity => {
                entity.show = false;
            });

            // 确保传入的数组有效
            if (!Array.isArray(deviceImeiArray) || deviceImeiArray.length === 0) {
                // console.warn('传入的设备号数组无效或为空');
                return;
            }


            // 创建一个映射，用于快速查找实体是否存在于设备号数组中
            const imeiToEntityMap = {};
            pointEntities.forEach(entity => {
                const imei = entity.id.split('_')[1]; // 假设id格式为'point_IMEI'
                imeiToEntityMap[imei] = entity;
            });

            // 判断是否只有一个设备，则显示设备
            if (deviceImeiArray.length === 1) {
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;
                        // this.currentSelectDevice =  imei
                        // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        window.viewer.trackedEntity = entity;
                        // entity.label.fillColor = Cesium.Color.RED;
                        // console.log('显示设备号: ' + imei);

                        window.viewer.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            } else {
                // 遍历设备号数组，处理每个设备号
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;

                        // // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        // if (entity.label) {

                        //     // entity.label.fillColor = Cesium.Color.RED;
                        //     console.log('显示设备号: ' + imei);
                        // }

                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });

            }


            // 清除之前可能设置的previousEntity的标签颜色（如果需要的话）
            // if (this.previousEntity && this.previousEntity.label && !(this.previousEntity.id.startsWith('point_') && deviceImeiArray.includes(this.previousEntity.id.split('_')[1]))) {
            //     this.previousEntity.label.fillColor = Cesium.Color.WHITE; // 或者设置为透明等，表示隐藏
            // }

            // 更新上一个实体的引用（这里可能需要更复杂的逻辑来管理多个实体的状态）
            // 由于我们现在是基于设备号数组来显示/隐藏，所以可能需要重新设计这部分逻辑
            // 例如，可以记录当前被显示的实体ID数组，而不是单个previousEntity
            // this.previousEntity = ...; // 根据需要更新
        },
        // TODO  优化 不行 绘制广告牌
        // 起点经度纬度，和终点经度纬度，和圆的中心点经度纬度，和圆的半径，找出圆边和线的相交点绘制出广告牌 30公里
        createIntersectionBillboards(startLon, startLat, endLon, endLat, circleLon, circleLat, radiusInKm) {
            // 将经纬度转换为笛卡尔坐标
            function cartographicToCartesian(lon, lat, height = 0) {
                const cartographic = Cesium.Cartographic.fromDegrees(lon, lat, height);
                return Cesium.Ellipsoid.WGS84.cartographicToCartesian(cartographic);
            }

            // 计算线段与圆的相交点
            function lineCircleIntersection(lineStart, lineEnd, circleCenter, radius) {
                const direction = Cesium.Cartesian3.subtract(lineEnd, lineStart, new Cesium.Cartesian3());
                Cesium.Cartesian3.normalize(direction, direction);

                const toCenter = Cesium.Cartesian3.subtract(circleCenter, lineStart, new Cesium.Cartesian3());
                const a = Cesium.Cartesian3.dot(direction, direction);
                const b = 2.0 * Cesium.Cartesian3.dot(direction, toCenter);
                const c = Cesium.Cartesian3.dot(toCenter, toCenter) - radius * radius;

                const discriminant = b * b - 4 * a * c;

                const intersections = [];
                if (discriminant >= 0) {
                    const t1 = (-b - Math.sqrt(discriminant)) / (2.0 * a);
                    const t2 = (-b + Math.sqrt(discriminant)) / (2.0 * a);

                    if (t1 >= 0 && t1 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t1, new Cesium.Cartesian3())));
                    }
                    if (t2 >= 0 && t2 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t2, new Cesium.Cartesian3())));
                    }
                }

                return intersections;
            }

            // // 将笛卡尔坐标转换为经纬度
            // function cartesianToCartographic(cartesian) {
            //     return Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
            // }

            // 转换经纬度
            const startCartesian = cartographicToCartesian(startLon, startLat);
            const endCartesian = cartographicToCartesian(endLon, endLat);
            const circleCartesian = cartographicToCartesian(circleLon, circleLat);

            // 半径转换为米
            const radius = radiusInKm; // * 1000;

            // 计算相交点
            const intersections = lineCircleIntersection(startCartesian, endCartesian, circleCartesian, radius);

            console.log("计算相交点:", intersections);

            // TODO zheli 在相交点处添加广告牌
            intersections.forEach((intersection, index) => {
                const position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(intersection);
                window.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Cesium.Math.toDegrees(position.longitude), Cesium.Math.toDegrees(position.latitude)),
                    // billboard: {
                    //     image: require('@/assets/your-image.png'), // 使用 Vue 的 require 语法来加载本地图片
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    // },
                    id: `billboard_${index}`,
                    label: {
                        text: '123',
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        // scale : 1.5,       // 大小
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        // fillColor: Cesium.Material.fromType('#00ff00'), 
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // // showBackground: true, // 背景
                        // // backgroundColor: Cesium.Color.BLUE,
                        // pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                    }
                });
            });

        },

        // 随机颜色
        // getRandomColor() {
        //     var str = '#';
        //     //一个十六进制的值的数组
        //     var arr = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f'];
        //     for (var i = 0; i < 6;i++){
        //         var num = parseInt(Math.random() * 16); //随机产生0-15之间的一个数
        //         str += arr[num]; //产生的每个随机数都是一个索引,根据索引找到数组中对应的值,拼接到一起
        //     }
        //     return str;
        //     },
        // TODO 优化颜色方法 
        getRandomColor() {
            var str = '#';
            var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

            let color;
            do {
                color = '#';
                for (var i = 0; i < 6; i++) {
                    var num = parseInt(Math.random() * 16);
                    color += arr[num];
                }
            } while (this.generatedColors.has(color)); // 检查颜色是否已存在

            // 添加新颜色到集合中
            this.generatedColors.add(color);

            return color;
        },
        generateUniqueColor() {
            // 生成并设置新的唯一颜色
            this.uniqueColor = this.getRandomColor();

        },
        // 添加轨迹
        addLine(vals, color) {
            // 遍历每个线的坐标点数组
            vals.forEach((val) => {
                let positions = [];
                // 遍历当前线的每个坐标点
                val.forEach((item) => {
                    // 将经纬度转换为Cesium的Cartesian3坐标
                    positions.push(Cesium.Cartesian3.fromDegrees(item.lng, item.lat, item.height));
                });

                // 在viewer中添加一个新的线实体
                window.viewer.entities.add({
                    polyline: {
                        positions: positions,
                        // 线宽
                        width: 5,
                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                        // 路线颜色，这里可以使用传入的color参数或继续保持随机颜色 color ? Cesium.Color.fromCssColorString(color) :
                        material: Cesium.Color.fromRandom(),
                        // 是否贴地
                        clampToGround: false,
                    },
                });
            });
        },

        // 位置信息  list 对象 异步等待执行
        // TODO 拼装鸽子数据 该高度
        computeFlight(lists) {
            let properties = []; // 用于存储每个轨迹的SampledPositionProperty对象

            // 遍历外部数组，每个元素是一个包含轨迹点的内部数组
            for (var i = 0; i < lists.length; i++) {
                var property = new Cesium.SampledPositionProperty(); // 为当前轨迹创建一个新的SampledPositionProperty对象
                var list = lists[i]; // 当前轨迹的点集合

                // 遍历当前轨迹的点集合
                for (var z = 0; z < list.length; z++) {
                    var item = list[z];
                    // TODO 根据json 文件不一  time  和 gpsLocationTime   互相更换
                    // height  和  altitude
                    // "lng": 120.407847,
                    // "lat": 23.408993,
                    var thisTime = Cesium.JulianDate.fromDate(new Date(item.gpsLocationTime));
                    const height = (item?.altitude != null) ? (item.altitude + 1000) : 1000;
                    var position = Cesium.Cartesian3.fromDegrees(
                        item.lng,
                        item.lat,
                        height
                    );

                    // 添加每一个链接点的信息，到达的时间以及坐标位置
                    property.addSample(thisTime, position);
                }
                properties.push(property); // 将当前轨迹的SampledPositionProperty对象添加到数组中
            }
            return properties; // 返回包含所有轨迹SampledPositionProperty对象的数组
        },
        // 位置信息  list 数组
        computeFlightArrays(list) {
            var property = new Cesium.SampledPositionProperty();


            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < list.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < list[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lng, lat,time] = list[i][j][t];
                    var thisTime = Cesium.JulianDate.fromDate(new Date(list[i][j].time));
                    positions.push(Cesium.Cartesian3.fromDegrees(list[i][j].lng, list[i][j].lat, list[i][j].height));
                }
                // 添加每一个链接点的信息，到达的时间以及坐标位置
                property.addSample(thisTime, positions);
            }
            return property;
        },
        // 添加模型动画
        // 异步执行
        // TODO 主方法
        modelAnimate(list, modelGltf) {
            //添加路线 直播效果不要绘制线条
            //   let color = this.getRandomColor();
            //   this.addLine(list,color);



            // console.log("第一只鸽子开始时间："+list[0][0].lng);

            //  TODO 开始时间 ，  应该要自定义开始时间？  eventStartTime: 0, //   开始时间
            //   eventEndTime: 0,  // 结束时间
            //   var date = new Date(list[0][0].time);
            //   var start = Cesium.JulianDate.fromDate(date); //获取第一个点的时间 
            // this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
            // this.eventEndTime 




            //   // 设置始时钟始时间
            //   window.viewer.clock.startTime = this.startTime.clone();
            //   // 设置时钟当前时间
            //   window.viewer.clock.currentTime = this.startTime.clone();
            //   // 时间速率，数字越大时间过的越快
            //   window.viewer.clock.multiplier = 1;
            //          调用 位置信息
            let properties = this.computeFlight(list); // 注意这里变量名改为properties，因为返回的是数组
            // console.log("properties-->", properties);

            // if (properties.length === 0) {
            //     console.error('No flight paths found.');
            //     return;
            // }

            // 选择第一个轨迹的SampledPositionProperty对象
            // let firstProperty = properties[1];




            // 设置始终停止时间（基于第一个轨迹）
            // var times = firstProperty._property._times;
            // var stop = times[times.length - 1];

            // console.log("设置始终停止时间（基于第一个轨迹） ", times, stop);
            // 结束时间：
            // var stopdate = new Date(this.eventEndTime);
            // var stop = Cesium.JulianDate.fromDate(stopdate); //获取第一个点的时间 

            // window.viewer.clock.stopTime = this.stopTime.clone();

            // // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // // 时间轴（使用第一个轨迹的开始和停止时间）
            // window.viewer.timeline.zoomTo(this.startTime, this.stopTime);

            // // 循环执行，到达终止时间，重新从起点时间开始
            // window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;

            // 为每个轨迹创建一个实体（这里仅以第一个轨迹为例）
            properties.forEach((property, index) => {
                //    console.log("index-->", this.zz);
                let planeModel = window.viewer.entities.add({
                    // show: this.sendisHide,  // 是否显示
                    id: "point_" + this.list[index][0].deviceImei,   // 标识符
                    // 和时间轴关联（可以使用每个轨迹自己的时间范围，这里以第一个轨迹的时间为例）
                    availability: new Cesium.TimeIntervalCollection([

                        new Cesium.TimeInterval({
                            start: this.startTime, // 可以根据需要设置为每个轨迹自己的开始时间
                            stop: this.stopTime, // 可以根据需要设置为每个轨迹自己的停止时间
                        }),
                    ]),
                    position: property, // 使用当前轨迹的SampledPositionProperty
                    // 根据所提供的速度计算模型的朝向
                    orientation: new Cesium.VelocityOrientationProperty(property),
                    // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                    model: {
                        uri: modelGltf, // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                        // scale: 1, // 
                        minimumPixelSize: 128,  // 最小的模型像素
                        maximumScale: 2000,
                        //   runAnimations: false,//是否显示动画
                        // clampAnimations: true,//是否保持最后一针的动画

                    },
                    // 图片
                    //  billboard: {
                    //     image: require('@/assets/bike_online.png'),
                    //     scale : 1.5,       // 大小
                    //     // sizeInMeters: true,  // 大小是否以米为单位
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 1, 10000, 0), // 近远效果 透明度
                    //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                    //     },
                    // 点
                    // point: {
                    //         pixelSize: 10,
                    //         color: Cesium.Color.WHITE,
                    //         // outlineColor: Cesium.Color.BLUE,
                    //         // outlineWidth: 3,
                    //             // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    //             horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //             verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向
                    //             },

                    // // 广告牌
                    // 广告牌
                    // TODO 广告牌
                    label: {
                        text: this.getLastSixDigits(this.list[index][0].deviceImei),
                        scale: 1.5,       // 大小
                        font: '18px sans-serif',
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        fillColor: Cesium.Color.WHITE,
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // showBackground: true, // 背景
                        // backgroundColor: Cesium.Color.BLUE,
                        translucencyByDistance: new Cesium.NearFarScalar(10000, 1, 8000000, 0), // 近远效果 透明度
                        pixelOffset: new Cesium.Cartesian2(0, -100), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                        // font: '20px Helvetica',
                        // font: '14px sans-serif',
                        // fillColor: Cesium.Color.RED, 
                        // outlineWidth: 2,
                        // outlineColor: Cesium.Color.BLACK
                    },
                    // label:{
                    //     text: '123456',
                    //     scale : 1.5,       // 大小
                    //     style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    //     fillColor: Cesium.Color.RED, 
                    //     outlineColor: Cesium.Color.BLACK, // 轮廓线
                    //     outlineWidth: 2,    // // 轮廓线宽度
                    //     // showBackground: true, // 背景
                    //     // backgroundColor: Cesium.Color.BLUE,
                    //     pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    //     eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                    //     // font: '20px Helvetica',
                    //     // font: '14px sans-serif',
                    //     // fillColor: Cesium.Color.RED, 
                    //     // outlineWidth: 2,
                    //     // outlineColor: Cesium.Color.BLACK
                    // },
                    // 线条
                    // path:{
                    //     show: true,
                    //     leadTime : 0, // 显示时间
                    //     trailTime : 15, // 尾迹时间
                    //     resolution : 1, // 时间分辨率
                    //     width : 2, // 宽度
                    //     material : new Cesium.PolylineGlowMaterialProperty({ // 材质
                    //         glowPower : 0.2,
                    //         color : Cesium.Color.RED, 
                    //     }),

                    // }
                    // TODO 加了轨迹线就巨卡
                    path: {
                        leadTime: 0,
                        resolution: 120,
                        // material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE ),

                        material: new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString(this.getRandomColor())),
                        width: 2
                    }

                });


            });
        },
        //创建模型
        addModel(url, height) {
            window.viewer.entities.removeAll();

            const position = Cesium.Cartesian3.fromDegrees(
                104.03697, 34.43811,
                height
            );
            const heading = Cesium.Math.toRadians(135); // 指向
            const pitch = 0;    // 视角
            const roll = 0;     // 偏移
            const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
            const orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );

            const entity = window.viewer.entities.add({
                name: url,
                position: position,
                orientation: orientation, // 方向
                model: {
                    uri: url,
                    minimumPixelSize: 328,  // 最小的模型像素
                    maximumScale: 20000,
                    // runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    //裁剪 ok
                    // clippingPlanes : new Cesium.ClippingPlaneCollection({
                    //     planes: [
                    //         new Cesium.ClippingPlane(new Cesium.Cartesian3(0,0,1), 0),

                    //     ],
                    //     edgeWidth: 1,
                    //     edgeColor:  Cesium.Color.RED,
                    //     enabled: true,
                    //     edgeMaterial: new Cesium.PolylineOutlineMaterialProperty({
                    //         color: Cesium.Color.RED,
                    //         outerWidth:1,
                    //         outlineColor: Cesium.Color.BLACK,

                    //     }),
                    // }),

                },
            });

            window.viewer.trackedEntity = entity;
        },
        // TODO 截取设备号保留后6位字符
        getLastSixDigits(str) {
            // 检查字符串长度是否足够
            if (str.length >= 6) {
                return str.slice(-6); // 从字符串末尾开始截取6个字符
            } else {
                // 如果字符串长度不足6，直接返回整个字符串
                return str;
            }
        },
        // TODO 11监听时钟变化
        mOnTick(clock) {
            var currentTime = clock.currentTime;
            this.EchelonTime = this.CesiumDateTimeXFormatter(currentTime);


        },

        // cesium时钟时间格式化函数
        CesiumTimeFormatter(datetime, viewModel) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")} : ${second.padStart(2, "0")}`;
        },

        // cesium时钟日期格式化函数
        CesiumDateFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            return `${gregorianDT.year}年${gregorianDT.month}月${gregorianDT.day}日`;
        },

        // cesium时间轴格式化函数 年月日时分
        CesiumDateTimeFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            return `${gregorianDT.year}年${gregorianDT.month}月${gregorianDT.day}日${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
        },

        // cesium时间轴格式化函数 年月日时分秒
        CesiumDateTimeXFormatter(datetime, viewModel, ignoredate) {

            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:${second.padStart(2, "0")}`;
        },
        // 按钮点击事件
        switchAnimation() { // TODO 切换实体
            // // 播放暂停
            // if (window.viewer.clock.shouldAnimate) {
            //     window.viewer.clock.shouldAnimate = false;
            // }

            // 获取所有以'point_'开头的实体 和显示的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_') && entity.show;
            });


            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                console.log("没有找到以'point_'开头的实体");
                return;
            }


            // 更新当前索引并获取当前选中的实体
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;
            const targetEntity = pointEntities[this.currentIndex];

            // 如果存在上一个实体，则将其标签颜色设置为白色
            if (this.previousEntity && this.previousEntity.label) {
                this.previousEntity.label.fillColor = Cesium.Color.WHITE;
            }

            // 检查目标实体是否存在并更新其标签颜色
            if (Cesium.defined(targetEntity) && targetEntity.label) {
                targetEntity.label.fillColor = Cesium.Color.RED;
                const imei = targetEntity.id.split('_')[1]; // 假设id格式为'point_IMEI'
                this.findDevicePigeonData(imei);
            } else {
                console.warn('目标实体不存在或其标签未定义。');
            }

            // 更新上一个实体的引用
            this.previousEntity = targetEntity;

            // 切换到目标实体
            // TODO 


            window.viewer.trackedEntity = targetEntity;
            // window.viewer.zoomTo(targetEntity);
            // this.lockView(targetEntity);

        },

        // TODO视野到指定的实体
        lockView(entity) {
            let lockedEntity = entity;

            // 监听摄像机的移动事件，并在需要时重置摄像机位置
            var camera = window.viewer.camera;
            var handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);

            handler.setInputAction(function (movement) {
                // 检查摄像机是否已被用户移动
                if (camera.changed) {
                    // 重置摄像机位置到锁定的实体
                    flyToEntity(lockedEntity);

                    // 重置changed标志，以避免无限循环
                    camera.changed = false; // 注意：Cesium的Camera对象没有直接的changed属性，这里是为了示例说明。
                    // 实际上，您可能需要使用其他方法来检测摄像机的变化。
                    // 一种可能的方法是设置一个定时器来定期检查摄像机的位置，并与之前的位置进行比较。
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // 注意：上面的代码片段中有一个问题，即Cesium的Camera对象没有changed属性来直接检测变化。
            // 一种替代方法是设置一个定时器或使用Cesium的动画帧事件来定期检查摄像机的位置。
            // 但是，这种方法可能会引入性能问题，特别是当场景中有大量实体时。
            // 更好的方法可能是监听特定的用户交互事件（如拖动、缩放等），并在这些事件发生时重置摄像机位置。
            // 由于Cesium没有提供直接的“锁定视野”API，因此实现这一功能可能需要一些创造性的解决方案。
        },
        //TODO 移除，隐藏 所有鸽子实体 
        removeAllEntity() {
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });
            // for (var i = 0; i < pointEntities.length; i++) {
            //     window.viewer.entities.remove(pointEntities[i]);
            // }

        },
        // 全选 显示所有实体
        showAllEntity() {
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            pointEntities.forEach(function (entity) {
                entity.show = true;         // 显示所有实体
            });

        },
        startAnimation() { // TODO 开始播放
            window.viewer.clock.shouldAnimate = true;


            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });


            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                console.log("没有找到以'point_'开头的实体");
                return;
            }

            // 更新当前索引并获取当前选中的实体
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;
            const targetEntity = pointEntities[this.currentIndex];


            // 如果存在上一个实体，则将其标签颜色设置为白色
            if (this.previousEntity && this.previousEntity.label) {
                this.previousEntity.label.fillColor = Cesium.Color.WHITE;
            }

            // 检查目标实体是否存在并更新其标签颜色
            if (Cesium.defined(targetEntity) && targetEntity.label) {
                targetEntity.label.fillColor = Cesium.Color.RED;
                // TODO 这里
                const imei = targetEntity.id.split('_')[1]; // 假设id格式为'point_IMEI'
                this.findDevicePigeonData(imei);
            } else {
                console.warn('目标实体不存在或其标签未定义。');
            }
            // 更新上一个实体的引用
            this.previousEntity = targetEntity;
            // console.log(更新上一个实体的引用 ,this.previousEntity);
            // 切换到目标实体
            window.viewer.trackedEntity = targetEntity;

        },
        pauseAnimation() {   // 暂停播放
            window.viewer.clock.shouldAnimate = false;


        },
        resetAnimation() {  // TODO 重置播放
            // 重置实体索引
            window.viewer.clock.shouldAnimate = false;
            this.currentIndex = -1;

            let currentTime = Cesium.JulianDate.fromDate(new Date(1699403520000)); // 中国时间 开始时间
            //  TODO 设置时钟当前时间 改了下
            // window.viewer.clock.currentTime = this.startTime.clone();
            // 设置指定时间  Cesium Viewer 的当前时间为这个 JulianDate
            window.viewer.clock.currentTime = currentTime.clone();


            // window.viewer.clock.currentTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime));


            // 跳到地图起点
            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            window.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000),
                // orientation: {
                //     // 指向 z 180
                //     // heading:Cesium.Math.toRadians(-10),
                //     // 视角 y
                //     pitch:Cesium.Math.toRadians(90),
                //     // 视角 x
                //     roll: -3.0,
                // },

                duration: 1, // 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }

            });

        },
        speedUpAnimation() {  // 加速播放
            window.viewer.clockViewModel.multiplier *= 2;
        },
        speedDownAnimation() {   // 减速播放
            window.viewer.clockViewModel.multiplier /= 2;
        },





    }

}
</script>

<style lang="less" scoped>
#container {

    width: 100%;
    height: 100%;
}

/* .amap-ctrl-list-layer{
    right: 1108px;
  } */



.botOtherBox {
    // position: absolute;
    // bottom: 30px;
    // left: 300px;
    // height: 60px;
    // align-items: center;
    // z-index: 2;
    // display: flex;
    // flex-wrap: wrap;

    position: absolute;
    width: 100%;
    bottom: 30px;
    /* left: 300px; */
    /* height: 60px; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* margin: 0 auto; */
    -ms-flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.botOtherItem {
    padding: 8px 18px;
    background: rgba(0, 9, 18, .5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
}

.botOtherItem:hover {

    color: #1af2ff;

}




.input-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    width: 22rem;
    border-width: 0;
    border-radius: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.75rem 1.25rem;
}

.input-card .btn {

    margin-right: 1.2rem;
    width: 9rem;
}

.input-card .btn:last-child {
    margin-right: 0;
}

/** 排名样式 */
.playBox {
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    user-select: none;
    height: 42px;
    z-index: 99;
}

.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.playBoxItem {
    width: 172px;
    height: 42px;
    padding: 0 15px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    line-height: 1;
    position: relative;
}

.playBoxItem:hover {
    color: #1af2ff;
}


.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.imgBox {
    width: 31px;
    height: 31px;
    margin-right: 8px;
}

img {
    width: 100%;
    height: 100%;
}

.icons {
    font-size: 20px;
    transition: all .3s;
}

.el-icon {

    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: #fff;
    font-size: inherit;
}

.botBox {
    display: block;
    /* 改为block以使用绝对定位 */
    position: absolute;
    bottom: 100%;
    /* 初始位置在imgBox的下方，但我们将通过JS调整它 */
    left: 0;
    width: 146px;
    /* max-height不再需要，因为我们将使用v-if来控制显示和隐藏 */
    transition: all 0.3s;
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, 0.75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    overflow: hidden;
    /* 初始时隐藏，通过v-if控制 */
    /* 注意：由于我们使用了v-if，max-height属性不再需要 */
}

/* 添加过渡效果 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.botBtnItem {
    width: 100%;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

}

.botBtnItem:hover {
    color: #1af2ff;
}


/* 梯队数据样式 */
.echelonBox.on {
    transform: translate(0);
}

.echelonBox {
    padding: 20px 30px 10px;
    width: 600px;
    background: rgba(0, 0, 0, .05);
    border-radius: 8px;
    font-size: 22px;
    color: #fff;
    position: fixed;
    top: 120px;
    right: 30px;
    transition: all .3s;
    transform: translate(200%);
    // user-select: none;
}

.el-icon {
    --color: inherit;
    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: var(--color);
    font-size: inherit;
}

.titBoxImg {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.closePlay2 {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
    font-size: 22px;
    width: 30px;
    height: 30px;
    background: #000;
    opacity: 0;
    border-radius: 50%;
    transition: all .3;
}


.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.infoBoxTit {
    margin-bottom: 20px;
    align-items: center;
    font-size: 28px;
    color: #fff;
    text-shadow: 0px 0px 10px #fff;
    line-height: 1;
}

.echelonItem {
    margin-bottom: 20px;
}

.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.echelonTit {
    font-size: 20px;
    color: #fff;
}

.echelonNum {
    font-size: 28px;
    color: #fff;
}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.homingTime {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}

.echelonCont {
    padding-right: 50px;
    width: 100%;
}

/* 轨迹数据 */

::v-deep {
    // .el-table th.el-table__cell {
    //     user-select: initial;
    //     }

    .el-table,
    .el-table__expanded-cell {
        background-color: #ffffff00;
    }

    .el-table th.el-table__cell {
        background-color: #ffffff00;
    }

    .el-table thead {
        color: #ffffff;
    }

    .el-table tr {
        background-color: #ffffff00;
        color: #ffffff;
    }

    .el-table__empty-text {

        color: #ffffff;
    }

    //  .el-table--enable-row-hover .el-table__body tr:hover {
    //     background-color: #052531; 
    // }
    // 鼠标移上去
    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #3498dbcb;
    }

    // // 设置滚动条颜色
    // .el-table__body-wrapper {
    //     scrollbar-width: thin; /* 或 'auto' */
    //     scrollbar-color: #00a0e9 #ffffff00; /* 滑块颜色 轨道颜色 */
    // }

    // .el-table--scrollable-y .el-table__body-wrapper ::-webkit-scrollbar {
    //     width: 10px; /*滚动条的宽*/
    //     height: 10px; /*滚动条的高*/
    // }
    // .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
    //     box-shadow: 0px 1px 3px #0558f3 inset; /*滚动条的背景区域的内阴影*/
    //     border-radius: 10px; /*滚动条的背景区域的圆角*/
    //     background-color: #4a0735; /*滚动条的背景颜色*/
    // }




}


.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 14px;
        /*滚动条宽度*/
        height: 14px;
        /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #071e4a inset;
        /*滚动条的背景区域的内阴影*/
        border-radius: 10px;
        /*滚动条的背景区域的圆角*/
        background-color: #ffffff00;
        /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #00a0e9 inset;
        /*滚动条的内阴影*/
        border-radius: 10px;
        /*滚动条的圆角*/
        background-color: #00a0e9;
        /*滚动条的背景颜色*/
    }
}



hr {
    margin: 0;
    padding: 0;
}

.dynamics_data {
    display: flex;
}

.dynamics_data_info {
    flex: 1;
    display: flex;

}
</style>