import request from '@/api/request'

export function sendRemoteControl (data) {
  const req = {
    deviceImei: data.deviceImei,
    cmdValue: data.cmdValue
  }
  return request({
    url: 'gps/command/sendRemoteControl',
    method: 'post',
    data: req
  })
}

export function setTimeIntervalForTracking (data) {
  const req = {
    deviceImei: data.deviceImei,
    interval: data.interval
  }
  return request({
    url: 'gps/command/setTimeIntervalForTracking',
    method: 'post',
    data: req
  })
}

export function setTimeZone (data) {
  const req = {
    deviceImei: data.deviceImei,
    timeZoneValue: data.timeZoneValue
  }
  return request({
    url: 'gps/command/setTimeZone',
    method: 'post',
    data: req
  })
}

export function setAngleForTracking (data) {
  const req = {
    deviceImei: data.deviceImei,
    angle: data.angle
  }
  return request({
    url: 'gps/command/setAngleForTracking',
    method: 'post',
    data: req
  })
}

export function setOdometerInterval (data) {
  const req = {
    deviceImei: data.deviceImei,
    distance: data.distance
  }
  return request({
    url: 'gps/command/setOdometerInterval',
    method: 'post',
    data: req
  })
}

export function setTelePhoneForWiretapping (data) {
  const req = {
    deviceImei: data.deviceImei,
    phoneNumber: data.phoneNumber
  }
  return request({
    url: 'gps/command/setTelePhoneForWiretapping',
    method: 'post',
    data: req
  })
}

export function setSpeedLimit (data) {
  const req = {
    deviceImei: data.deviceImei,
    speed: data.speed
  }
  return request({
    url: 'gps/command/setSpeedLimit',
    method: 'post',
    data: req
  })
}

export function setMovementAlert (data) {
  const req = {
    deviceImei: data.deviceImei,
    meters: data.meters
  }
  return request({
    url: 'gps/command/setMovementAlert',
    method: 'post',
    data: req
  })
}

export function setAuthorizedPhoneNumber (data) {
  const req = {
    deviceImei: data.deviceImei,
    buttonNumber: data.buttonNumber,
    phoneNumberForReceiveSms: data.phoneNumberForReceiveSms,
    phoneNumberForReceiveCall: data.phoneNumberForReceiveCall
  }
  return request({
    url: 'gps/command/setAuthorizedPhoneNumber',
    method: 'post',
    data: req
  })
}
