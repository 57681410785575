<template>
  <div>
    <div id="container"></div>

    <div class="botOtherBox" v-if="coordinates" >
      <div class="botOtherItem" id="start" @click="startAnimation()">播放</div>
      <div class="botOtherItem" id="pause" @click="pauseAnimation()">暂停</div>
      <div class="botOtherItem" id="resume" @click="resumeAnimation()">继续</div>
      <div class="botOtherItem" id="stop" @click="stopAnimation()">停止</div>
    </div>

    <!-- <div class="input-card" v-if="coordinates">
      <h4>轨迹回放控制</h4>
      <div class="input-item">
        <input type="button" class="btn" value="开始动画" id="start" @click="startAnimation()" />
        <input type="button" class="btn" value="暂停动画" id="pause" @click="pauseAnimation()" />
      </div>
      <div class="input-item">
        <input type="button" class="btn" value="继续动画" id="resume" @click="resumeAnimation()" />
        <input type="button" class="btn" value="停止动画" id="stop" @click="stopAnimation()" />
      </div>
    </div> -->

  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: "AMapContainer",
  props: ['coordinates'],
  data() {
    return {

      AMap: null,
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map: null, //初始化 map 对象
      mouseTool: null,
      marker: null,
      lineArr: [],
    }
  },
  methods: {
    getRandomColor() {
      var str = '#';
      //一个十六进制的值的数组
      var arr = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f'];
      for (var i = 0; i < 6;i++){
        var num = parseInt(Math.random() * 16); //随机产生0-15之间的一个数
        str += arr[num]; //产生的每个随机数都是一个索引,根据索引找到数组中对应的值,拼接到一起
      }
      return str;
    },
    pauseAnimation() {
      this.marker.pauseMove();
    },
    resumeAnimation() {
      this.marker.resumeMove();
    },
    stopAnimation() {
      this.marker.stopMove();
    },
    startAnimation() {
      this.marker.moveAlong(this.lineArr, {
        // 每一段的时长
        duration: 1000,//可根据实际采集时间间隔设置 60000
        // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        autoRotation: true,
      });
    },


    initMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "6022b945a8a07f0bf3378d27646cf8f9",
      };


      //创建地图
      AMapLoader.load({
        key: "47a846f82b90a280e52b44220935a144", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.MoveAnimation', 'AMap.Geolocation', 'AMap.Scale', 'AMap.MapType'], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
        .then((AMap) => {
          this.AMap = AMap
          this.marker = null;


          //       // 坐标点数组
          //       var coordinates = [
          //   {
          //     name: '瑞金市',
          //     position: [116.02709, 25.88562],
          //     details: "瑞金位于江西省东南边陲，武夷山脉西麓，与福建省长汀县相邻，是中华苏维埃共和国临时中央政府诞生地，也是举世瞩目的云石山二万五千里长征出发地。长征是人类历史上的伟大奇迹，中央红军共进行了380余次战役战斗，攻占七百多座县城，行程约二万五千里。于1935年10月到达陕北，与陕北红军胜利会师。1936年10月红二方面军和红四方面军到达甘肃与红一方面军会师。红军三大主力会师，宣告红军长征胜利结束。",
          //     pic: " ",
          //   },
          //   {
          //     name: '于都县',
          //     position: [115.41544, 25.95184],
          //     details: "于都，是中央红军长征集结出发地、南方三年游击战争起源地、长征精神的发源地、中央苏区全红县和苏区精神的形成地之一，诞生了16位共和国将军。这片红色土地承载着中国共产党的初心和使命，见证了中国共产党的理想信念和革命意志。",
          //     pic: "",
          //   },
          //   {
          //     name: '广昌县',
          //     position: [116.32573, 26.83729],
          //     details: "国民党军队集中力量进攻广昌。毛泽东和张闻天都不主张进行广昌保卫战，但李德不采纳他们的正确意见。博古、李德等命令红军主力保卫并不利于坚守的广昌，同敌人进行“决战”。从4月10日开始，国民党军队向广昌发动进攻。参加保卫战的红一、红三军团和红五军团第十三师等部英勇奋战，坚守阵地，付出很大牺牲。4月27日，国民党军队集中10个师的兵力，在飞机、大炮的支援下会攻广昌。红军各部队依托阵地，打退敌军多次进攻，并实施“短促突击”战术，但未能摆脱被动局面。博古、李德被迫放弃坚守广昌的计划，命令红军撤离广昌。",
          //     pic: "https://5b0988e595225.cdn.sohucs.com/images/20190308/a5130af7caec458f8fcd665c2550e18f.jpeg",
          //   },
          //   {
          //     name: '赣县',
          //     position: [115.01161, 25.86076],
          //     details: "故事1934年10月18日说起，中革军委主席朱德签发了关于“野战军攻占古陂、新田地域的命令”，部署中央红军由王母渡、韩坊等地段突破粤军封锁。1934年10月21日傍晚，红八军团一部到达王母渡，在桃江渡口西渡桃江，突破国民党守军的第一道封锁线。对于红军来说，眼前是水深流急的宽阔河流，河对岸是严密把守的敌军，困难重重下，红军毅然向封锁线发起冲击。而就在红军渡河期间，被隐藏在碉堡里和山上战壕的敌军发现，一场激烈的战斗在所难免。",
          //     pic: "./assets/pigeon.gif",
          //   },
          //   {
          //     "name": "会昌县",
          //     "position": [115.786, 25.60039],
          //     "details": "会昌县位于江西省东南边陲，是红军长征的重要经过地之一。在长征时期，中央红军曾经历了艰苦的战斗和穿越，最终成功通过会昌县。这段历史见证了红军长征的坚韧和毅力。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "信丰县",
          //     "position": [114.92285, 25.38617],
          //     "details": "信丰县位于江西省东南部，是红军长征的途中必经之地。红军曾在信丰县进行战斗，最终成功突破敌人封锁线，继续前进。这段历史展示了红军的英勇与决心。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "汝城县",
          //     "position": [113.68493, 25.55036],
          //     "details": "汝城县位于湖南省东部，是红军长征的重要经过地之一。红军在汝城县进行了激烈的战斗，最终成功突围并继续前进。这段历史见证了红军长征的艰辛与勇敢。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "乐昌市",
          //     "position": [113.34746, 25.13014],
          //     "details": "乐昌市位于广东省北部，是红军长征的途中经过的城市之一。红军曾在乐昌市休整和补给，为继续前行提供了重要支持。这段历史展示了红军长征时期的耐心和智慧。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "宜章县",
          //     "position": [112.94887, 25.39957],
          //     "details": "宜章县位于湖南省中部，是红军长征的重要途经地之一。红军曾在宜章县与敌人进行了多次战斗，并成功突围继续前进。这段历史见证了红军的英勇和战斗精神。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "道县",
          //     "position": [111.60007, 25.52753],
          //     "details": "道县位于湖南省东部，是红军长征的途中经过的县城之一。红军曾在道县进行了顽强的战斗，并成功突破敌人封锁线，继续前进。这段历史展示了红军长征时期的毅力和团结。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "灌阳县",
          //     "position": [111.16083, 25.48932],
          //     "details": "灌阳县位于江西省东南边陲，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。灌阳县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "全州县",
          //     "position": [111.07304, 25.92861],
          //     "details": "全州县位于江西省东南边陲，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。全州县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "兴安县",
          //     "position": [110.67147, 25.61169],
          //     "details": "兴安县位于江西省东南边陲，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。兴安县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "龙胜各族自治县",
          //     "position": [110.01149, 25.79809],
          //     "details": "龙胜各族自治县位于江西省东南边陲，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。龙胜各族自治县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "通道桐族自治县",
          //     "position": [109.78449, 26.15794],
          //     "details": "通道桐族自治县位于瑞金，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。通道桐族自治县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "黎平县",
          //     "position": [109.13718, 26.22924],
          //     "details": "黎平县位于瑞金，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。黎平县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "瓮安县",
          //     "position": [107.47106, 27.0779],
          //     "details": "瓮安县位于瑞金，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。瓮安县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "余庆县",
          //     "position": [107.88825, 27.22536],
          //     "details": "余庆县位于瑞金，是中华苏维埃共和国临时中央政府诞生地。这里是举世瞩目的云石山二万五千里长征的出发地之一。余庆县是红军长征途中经过的重要地点之一，中央红军曾在此进行了战役战斗并攻占了该县城。红军长征行程约二万五千里，最终于1936年10月会师陕北红军，宣告胜利结束。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "遵义市",
          //     "position": [106.82922, 27.53625],
          //     "details": "遵义市位于贵州省中部地区，是中国共产党领导的红军长征的重要经过地之一。1935年1月15日至17日，在遵义会议上，毛泽东在中央苏区内实现了对王明“左”倾冒险主义错误的批判，确立了以毛泽东为代表的正确领导。遵义会议标志着中国共产党在长征途中取得转折性胜利，为红军的继续前进提供了坚强的政治保证。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "桐梓县",
          //     "position": [106.82502, 28.13321],
          //     "details": "桐梓县是贵州省的一个县级行政区，也是红军长征的重要经过地之一。1935年1月，中央红军从瑞金出发，经过桐梓县向西北方向前进，经历了艰苦曲折的长征路程。桐梓县为红军提供了支援和休整的地方，为红军继续前进做出了重要贡献。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "仁怀市",
          //     "position": [106.40156, 27.79234],
          //     "details": "仁怀市位于贵州省西南部，是红军长征的途经地之一。1935年1月，中央红军在瑞金出发后，经过仁怀市向北方向挺进，进行了艰苦卓绝的长征。仁怀市为红军提供了物资支援和休整的场所，为红军坚持长征战略起到了积极作用。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "习水县",
          //     "position": [106.20385, 28.3274],
          //     "details": "习水县位于贵州省东南部，是红军长征的重要经过地之一。1935年1月，中央红军从瑞金出发，经过习水县向西北方向前进，历经艰难险阻的长征。习水县为红军提供了支援和休整的地方，为红军继续前进作出了重要贡献。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "赤水市",
          //     "position": [105.69744, 28.59055],
          //     "details": "赤水市位于贵州省，是红军长征的重要经过地之一。在长征中，红军曾在赤水河畔多次与敌人进行战斗，展现了坚定的革命意志和英勇的战斗精神。赤水市地势崎岖，风景秀丽，有着丰富的自然资源和独特的人文景观，吸引着众多游客前来观光旅游。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "扎西镇（威信县）",
          //     "position": [105.04818, 27.840693],
          //     "details": "扎西镇位于云南省威信县，是红军长征的重要经过地之一。在长征时期，红军曾在扎西镇附近进行过多次战斗和休整，为后续的长征行动积蓄力量。扎西镇地处高山峡谷之间，自然风光优美，是旅游观光的热门目的地。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "富源县",
          //     "position": [104.25485, 25.67422],
          //     "details": "富源县位于云南省，是红军长征的途经地之一。在长征过程中，红军曾在富源县进行过战斗与休整，为长征的继续提供了保障。富源县境内山川秀美，风景如画，拥有丰富的旅游资源和独特的民族风情。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "禄劝彝族苗族自治县",
          //     "position": [102.47145, 25.55128],
          //     "details": "禄劝彝族苗族自治县位于云南省，是红军长征的重要经过地之一。在长征过程中，红军曾在禄劝县附近进行过战斗与休整，为长征胜利做出了贡献。禄劝县地处高海拔地区，气候宜人，拥有得天独厚的自然风光和独特的民族文化。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "会理市",
          //     "position": [102.24457, 26.65527],
          //     "details": "会理市位于四川省，是红军长征的重要经过地之一。在长征中，红军曾在会理市周边进行过多次战斗和休整，展现了顽强的意志和英勇的作战能力。会理市地处山区，风景秀丽，是旅游观光和户外探险的热门目的地。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "冕宁县",
          //     "position": [102.17678, 28.55018],
          //     "details": "冕宁县位于四川省，是红军长征的重要经过地之一。在长征中，红军曾在冕宁县周边进行过多次战斗和休整，展现了顽强的意志和英勇的作战能力。冕宁县地处高山峡谷之间，风景秀丽，是旅游观光和户外探险的热门目的地。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "石棉县",
          //     "position": [102.35944, 29.22799],
          //     "details": "石棉县位于四川省，是红军长征的重要经过地之一。在长征中，红军曾在石棉县附近进行过战斗与休整，为长征的继续提供了保障。石棉县境内山川秀美，风景如画，拥有丰富的旅游资源和独特的民族风情。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "泸定县",
          //     "position": [102.23471, 29.91412],
          //     "details": "泸定县位于四川省，是红军长征的途经地之一。在长征过程中，红军曾在泸定县进行过战斗与休整，为长征胜利做出了贡献。泸定县地处高海拔地区，气候宜人，拥有得天独厚的自然风光和独特的民族文化。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "宝兴县",
          //     "position": [102.81457, 30.36806],
          //     "details": "宝兴县位于四川省，是红军长征的重要经过地之一。在长征中，红军曾在宝兴县周边进行过多次战斗和休整，展现了坚定的革命意志和英勇的战斗精神。宝兴县地势崎岖，风景秀丽，有着丰富的自然资源和独特的人文景观，吸引着众多游客前来观光旅游。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "小金县",
          //     "position": [102.3644, 30.99918],
          //     "details": "小金县位于四川省，是红军长征的重要经过地之一。在长征中，红军曾在小金县附近进行过战斗与休整，为长征胜利做出了贡献。小金县地处山区，风景秀丽，是旅游观光和户外探险的热门目的地。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "马尔康市",
          //     "position": [102.20644, 31.90585],
          //     "details": "马尔康市位于四川省阿坝藏族羌族自治州，是红军长征途中的重要经过地之一。红军长征时期，红二十五军经过马尔康市，并在此与红一方面军胜利会师。马尔康市地处川西高原腹地，素有“金城关”之称，地势险要，自古以来就是军事要地。马尔康市不仅具有重要的历史意义，还拥有壮丽的自然风光，吸引了众多游客前来观光旅游。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "红原县",
          //     "position": [102.54438, 32.79087],
          //     "details": "红原县位于四川省阿坝藏族羌族自治州，是红军长征途中的重要经过地之一。红军长征时期，红二十五军曾在红原县进行战斗，并在附近地区休整补给。红原县地处川西高原，地形复杂多样，风景秀丽。这里有茫茫草原、雄伟山川和壮丽河流，被誉为“天然的动植物王国”，是旅游观光和生态探险的好去处。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "松潘县",
          //     "position": [103.59893, 32.63813],
          //     "details": "松潘县位于四川省阿坝藏族羌族自治州，是红军长征途中的重要经过地之一。红军长征时期，红二十五军曾在松潘县一带进行战斗，并展开艰苦的长征行程。松潘县地处大凉山腹地，气候凉爽宜人，境内风光秀丽，拥有众多自然景观和民族文化资源，是旅游度假和探险徒步的热门目的地。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "若尔盖县",
          //     "position": [102.96187, 33.57586],
          //     "details": "若尔盖县位于四川省阿坝藏族羌族自治州，是红军长征途中的重要经过地之一。红军长征时期，红二十五军曾在若尔盖县一带进行战斗，为红军胜利会师作出了重要贡献。若尔盖县地处川西高原，地形辽阔壮观，拥有丰富的草原资源和特色民俗文化，是旅游观光和体验牧民生活的绝佳选择。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "迭部县",
          //     "position": [103.22175, 34.05587],
          //     "details": "迭部县位于陕西省，是红军长征的重要经过地之一。长征途中，红军曾在迭部县进行战斗，并向附近的县城发起攻击。迭部县地处武夷山脉西麓，风景秀丽，是红军长征历史的见证。红军在这里展现了顽强的意志和英勇的战斗精神，为保卫红色政权和革命事业做出了巨大贡献。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "岷县",
          //     "position": [104.03697, 34.43811],
          //     "details": "岷县位于陕西省，是红军长征的重要经过地之一。红军曾在岷县周边地区组织了游击战斗和群众运动，为争取群众支持和扩大革命力量发挥了重要作用。岷县地势险要，山河壮丽，成为红军长征史上的重要一站。红军在这里取得了重要的战果，为革命事业的胜利迈出了坚实的步伐。",
          //     "pic": require('@/assets/pigeon_s.gif')
          //   },
          //   {
          //     "name": "通渭县",
          //     "position": [105.24219, 35.21087],
          //     "details": "通渭县位于陕西省，是红军长征的重要经过地之一。红军曾在通渭县一带展开游击战斗和群众工作，与敌人进行顽强抗战，并有效组织了群众力量，为红军长征提供了重要支持。通渭县地处山水之间，景色秀丽，是红军长征历史的重要见证。红军在这里书写了革命的篇章，为中国革命事业的胜利做出了不可磨灭的贡献。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "会宁县",
          //     "position": [105.05334, 35.69291],
          //     "details": "会宁县位于陕西省，是红军长征的重要经过地之一。红军曾在会宁县周边地区开展游击战斗和群众工作，与敌人进行英勇斗争，并积极组织群众力量，为红军长征提供了重要支持。会宁县地理位置优越，自然风光优美，成为红军长征史上的重要一站。红军在这里书写了壮丽的革命篇章，为中国革命的胜利作出了重大贡献。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "西吉县",
          //     "position": [105.72911, 35.96385],
          //     "details": "西吉县是中央红军长征途经的地方之一。长征是人类历史上的伟大壮举，中央红军在长征过程中经历了380余次战役战斗，攻占了七百多座县城，行程约二万五千里。西吉县是红军长征胜利结束前最后一个战胜的县城，标志着红军胜利走出了险境，展开了新的征程。",
          //     "pic": " "
          //   },
          //   {
          //     "name": "定边县",
          //     "position": [107.60128, 37.59454],
          //     "details": "定边县是中央红军长征途经的重要站点之一。长征是人类历史上的伟大壮举，中央红军在长征过程中经历了380余次战役战斗，攻占了七百多座县城，行程约二万五千里。定边县是红军长征早期阶段的一个重要战略目标，红军在这里展示了坚韧的意志和出色的作战能力。",
          //     "pic": ""
          //   },
          //   {
          //     "name": "吴起县",
          //     "position": [108.17587, 36.92728],
          //     "details": "吴起县是中央红军长征途经的重要驻地之一。长征是人类历史上的伟大壮举，中央红军在长征过程中经历了380余次战役战斗，攻占了七百多座县城，行程约二万五千里。吴起县是红军长征中一个具有重要意义的驻地，红军在这里休整、整编，为继续前进积蓄力量。",
          //     "pic": require('@/assets/pigeon.gif')
          //   }
          // ];



          // this.lineArr = [[116.478935,39.997761],[116.478939,39.997825],[116.478912,39.998549],[116.478912,39.998549],[116.478998,39.998555],[116.478998,39.998555],[116.479282,39.99856],[116.479658,39.998528],[116.480151,39.998453],[116.480784,39.998302],[116.480784,39.998302],[116.481149,39.998184],[116.481573,39.997997],[116.481863,39.997846],[116.482072,39.997718],[116.482362,39.997718],[116.483633,39.998935],[116.48367,39.998968],[116.484648,39.999861]];
          //创建卫星图层
          // var satellite = new AMap.TileLayer.Satellite();
          // //创建路网图层
          // var roadNet = new AMap.TileLayer.RoadNet();
          //创建楼块图层
          var buildings = new AMap.Buildings({
            zooms: [17, 20],
            zIndex: 10,
            heightFactor: 2, //2倍于默认高度（3D 视图下生效）
          });
          // // 创建标签图层，显示地名
          // var labelLayer = new AMap.TileLayer.Label({
          //   zIndex: 20, // 设置标签图层的堆叠顺序，确保它在其他图层之上
          // });
          this.map = new AMap.Map("container", {
            pitch: 50, //地图俯仰角度，有效范围 0 度- 83 度
            viewMode: '3D', //地图模式

            layers: [buildings], //地图图层的数组
            terrain: true, //开启地形图
            rotateEnable: true, //是否开启地图旋转交互 鼠标右键 + 鼠标画圈移动 或 键盘Ctrl + 鼠标左键画圈移动
            pitchEnable: true, //是否开启地图倾斜交互 鼠标右键 + 鼠标上下移动或键盘Ctrl + 鼠标左键上下移动
            zoom: 14, //初始化地图层级
            rotation: -15, //初始地图顺时针旋转的角度
            zooms: [2, 20], //地图显示的缩放级别范围
            center: [116.02709, 25.88562] //初始地图中心经纬度 [116.02709, 25.88562]
          });



          this.map.addControl(new AMap.Scale());
          this.map.addControl(new AMap.MapType({

            defaultType: 1,//0代表默认，1代表卫星
            showRoad: true //  是否显示路网
          }));


          if (this.coordinates) {
            console.log(this.coordinates.length,"接收道德数据是:", this.coordinates)
            this.lineArr = this.coordinates[3];
            // this.lineArr = this.coordinates.map(function (coordinate) {
            //   return coordinate.position;
            // });

            // 循环生产轨迹数据线
            for (var i = 0; i <  this.coordinates.length; i++) {

              var oneMarker = new AMap.Marker({
              map: this.map,
              position: this.coordinates[i][0],
              // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
               icon: new AMap.Icon({            
                     image: require('@/assets/pigeon.gif'),
                      size: new AMap.Size(128, 128),  //图标大小
                      imageSize: new AMap.Size(64,64)
                     }),
              offset: new AMap.Pixel(-13, -26),
            });

               // 绘制轨迹
            var polyline = new AMap.Polyline({
              map: this.map,
              path: this.coordinates[i],
              showDir: true,
              strokeColor: this.getRandomColor(),  //线颜色
              // strokeOpacity: 1,     //线透明度
              strokeWeight: 6,      //线宽
              // strokeStyle: "solid"  //线样式
            });

            // var passedPolyline = new AMap.Polyline({
            //   map: this.map,
            //   strokeColor: "#AF5",  //线颜色
            //   strokeWeight: 6,      //线宽
            // });
            
            // lastmarkerArray.push()
            }
            
            this.marker = new AMap.Marker({
              map: this.map,
              position: this.lineArr[0],
              // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
               icon: new AMap.Icon({            
                     image: require('@/assets/pigeon.gif'),
                      size: new AMap.Size(128, 128),  //图标大小
                      imageSize: new AMap.Size(64,64)
                     }),
              offset: new AMap.Pixel(-13, -26),
            });



            // 绘制轨迹
            var polyline = new AMap.Polyline({
              map: this.map,
              path: this.lineArr,
              showDir: true,
              strokeColor: this.getRandomColor(),  //线颜色
              // strokeOpacity: 1,     //线透明度
              strokeWeight: 6,      //线宽
              // strokeStyle: "solid"  //线样式
            });

            var passedPolyline = new AMap.Polyline({
              map: this.map,
              strokeColor: "#AF5",  //线颜色
              strokeWeight: 6,      //线宽
            });


            this.marker.on('moving', function (e) {
              passedPolyline.setPath(e.passedPath);
              this.map.setCenter(e.target.getPosition(), true)
            }.bind(this));

          }







          // var that = this;
          // coordinates.forEach(coord => {
          //   var marker = new AMap.Marker({
          //     position: coord.position,

          //     icon: new AMap.Icon({            
          //         image: coord.pic,
          //         size: new AMap.Size(128, 128),  //图标大小
          //         imageSize: new AMap.Size(64,64)
          //     }) ,
          //     map: this.map
          //   });

          //    // 点击坐标点
          //    marker.on('click', function () {
          //     var targetLocation = coordinates.find(item => item.name === coord.name);
          //     that.details = targetLocation.details
          //     that.pic = targetLocation.pic
          //     that.dialogVisible = true
          //     console.log(targetLocation)
          //   });

          //   var text = new AMap.Text({
          //     text: coord.name,
          //     position: coord.position,
          //     offset: new AMap.Pixel(0, -50),
          //     style: {
          //       backgroundColor: '#fff',
          //       border: 'none',
          //       fontSize: '12px',
          //       color: '#000'
          //     }
          //   });

          //   this.map.add(text);



          // });

          // 调整地图视野以适应所有标记点
          // this.map.setFitView()


          // 轨迹
          // AMap.plugin('AMap.MoveAnimation', function(){
          //     var marker, lineArr = [[116.478935,39.997761],[116.478939,39.997825],[116.478912,39.998549],[116.478912,39.998549],[116.478998,39.998555],[116.478998,39.998555],[116.479282,39.99856],[116.479658,39.998528],[116.480151,39.998453],[116.480784,39.998302],[116.480784,39.998302],[116.481149,39.998184],[116.481573,39.997997],[116.481863,39.997846],[116.482072,39.997718],[116.482362,39.997718],[116.483633,39.998935],[116.48367,39.998968],[116.484648,39.999861]];

          //     // var map = new AMap.Map("container", {
          //     //     resizeEnable: true,
          //     //     center: [116.397428, 39.90923],
          //     //     zoom: 17
          //     // });

          //     marker = new AMap.Marker({
          //         map: this.map,
          //         position: [116.478935,39.997761],
          //         icon: new AMap.Icon({            
          //                image: require('@/assets/pigeon.gif'),
          //                 size: new AMap.Size(128, 128),  //图标大小
          //                 imageSize: new AMap.Size(64,64)
          //                }),
          //         offset: new AMap.Pixel(-13, -26),
          //     });

          //     // 绘制轨迹
          //     var polyline = new AMap.Polyline({
          //         map: this.map,
          //         path: lineArr,
          //         showDir:true,
          //         strokeColor: "#28F",  //线颜色
          //         // strokeOpacity: 1,     //线透明度
          //         strokeWeight: 6,      //线宽
          //         // strokeStyle: "solid"  //线样式
          //     });

          //     var passedPolyline = new AMap.Polyline({
          //         map: this.map,
          //         strokeColor: "#AF5",  //线颜色
          //         strokeWeight: 6,      //线宽
          //     });



          this.map.setFitView();



          // });



        })
        .catch((e) => {
          console.log("zhlei ", e);
        });





    },



  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  unmounted() {
    this.map?.destroy();
  },
}
</script>

<style scoped>
#container {

  width: 100%;
  height: 100%;
}

/* .amap-ctrl-list-layer{
  right: 1108px;
} */


.botOtherBox {
    position: absolute;
    bottom: 30px;
    left: 300px;
    height: 60px;
    align-items: center;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    margin-left:200px;
}

.botOtherItem{
  padding: 8px 18px;
    background: rgba(0, 9, 18, .5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
}

.input-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  width: 22rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;
}

.input-card .btn {

  margin-right: 1.2rem;
  width: 9rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
</style>